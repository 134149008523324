import React, { useState, useEffect } from "react";
import Modal from "../../Common/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../../..";
import { observer } from "mobx-react-lite";
import { DataTable } from "../../Common/DataTable/DataTable";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { Spinner } from "../../Common/Spinner";
import { DataTable3 } from "../../Server/DataTable3";
import Form, { FormBody, TextInput} from '../../Common/Form';

const ServiceProvider = observer(() => {
    const rootStore = useContext(RootContext);
    const serverStore = rootStore.serverStore;
    const serviceProviderStore = rootStore.serviceProviderStore;

    const [showAddServiceProvider, setShowAddServiceProvider] = useState(false);
    const [showAddRedirectingServer, setShowAddRedirectingServer] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);

    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (!serviceProviderStore.serviceProvider.length) serviceProviderStore.fetchServiceProvider();
    }, []);

    const [serviceProviderInfo, setServiceProviderInfo] = useState({
        caption: "",
        description: ""
    });

    const setNewForm = (e) => {
        const { name, value } = e.target;
        setServiceProviderInfo((prev) => ({ ...prev, [name]: value }));
    };


    const addServiceProvider = async (e) => {
        e.preventDefault();
        if (!serviceProviderInfo.caption || !serviceProviderInfo.description) {
            toast.error("Missing fields");
            return;
        }
        const addedServiceProvider = await serviceProviderStore.addServiceProvider(serviceProviderInfo);

        if (!addedServiceProvider.id) {
            await serviceProviderStore.fetchServiceProvider();

            setServiceProviderInfo({ caption: "", description: "" });
        }

        setShowAddServiceProvider(false)
    };


    const deleteServiceProvider = async (id) => {
        const confirm = window.confirm("Delete?");
        if (selectedRows.length != 0 && confirm) {
            await Promise.all(selectedRows.map((id) => serviceProviderStore.deleteServiceProvider(id)));
            setSelectedRows([]);
        } else if (confirm) {
            await serviceProviderStore.deleteServiceProvider(id);
        }
    }

    const toggleCheckbox = (id) => {
        const isSelected = selectedRows.includes(id);

        if (isSelected) {
            setSelectedRows((prevRows) => prevRows.filter((rowId) => rowId !== id));
        } else {
            setSelectedRows((prevRows) => [...prevRows, id]);
        }
    };



    const handleCheckboxChange = (id) => {
        const isSelected = selectedRows.includes(id);

        if (isSelected) {
            setSelectedRows((prevRows) => prevRows.filter((rowId) => rowId !== id));
        } else {
            setSelectedRows((prevRows) => [...prevRows, id]);
        }

        handleCheckboxChange && handleCheckboxChange(selectedRows);
    };


    return (
        <div>
            {serviceProviderStore.loading ? (
                <Spinner />
            ) : (
                <DataTable3
                    title="Service Provider List"
                    data={serviceProviderStore.serviceProvider
                        .map((item, index) => {
                            return {
                                ...item,
                                SN: index + 1,
                            }
                        })

                    }
                    headers={[
                        { key: "SN", label: "SN" },
                        { key: "caption", label: "Caption" },
                        { key: "description", label: "Description" },
                    ]}
                    handleAdd={() => setShowAddServiceProvider(true)}
                    handleEdit={(id) => {
                        navigate(`/configuration/serviceprovider/${id}`);
                    }}
                    handleDelete={(e) => deleteServiceProvider(e)}
                    handleCheckboxChange={(id) => toggleCheckbox(id, selectedRows, setSelectedRows)}
                />)}

            {showAddServiceProvider && (
                <Modal
                    title={"Add Service Provider"}
                    closeModal={() => {
                        setShowAddServiceProvider(false);
                        setServiceProviderInfo({ caption: "", description: "" });
                    }}
                >

                    <Form>
                        <FormBody>
                            <TextInput label={'Caption'} name="caption"  value={serviceProviderInfo.caption} onChange={setNewForm} />
                            <TextInput label={'Description'} name="description"  value={serviceProviderInfo.description} onChange={setNewForm} />
                            <br/>
                            <button onClick={(e) => addServiceProvider(e)}>Submit</button>
                        </FormBody>
                    </Form>
                </Modal>
            )}
            <Outlet />
        </div>

    );
});


export default ServiceProvider