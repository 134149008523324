import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import './index.css';
import App from './App';
import { RootStore } from './stores/root_store';
import { router } from './Routes';

export const RootContext = createContext()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RootContext.Provider value={new RootStore()}>
    <RouterProvider router={router} />
    </RootContext.Provider>
  </React.StrictMode>
);
