import React, { useState, useEffect } from "react";
import Modal from "../../Common/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../../../index";
import { observer } from "mobx-react-lite";
import { useParams, useNavigate } from "react-router-dom";
import Form, { FormBody, TextInput } from '../../Common/Form';

const EditPlan = observer(() => {
  const rootStore = useContext(RootContext);
  const planStore = rootStore.planStore;

  const [caption, setCaption] = useState("");
  const [description, setDescription] = useState("");
  const [volume, setVolume] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!planStore.plan.length) planStore.fetchplan();
    const currentPlan = planStore.plan.find((plan) => plan.id === id);
    setCaption(currentPlan?.caption);
    setDescription(currentPlan?.description);
    setVolume(currentPlan?.volume);
  }, []);

  const editplan = async (e) => {
    e.preventDefault();
    if (!caption || !description) {
      toast.error("Missing fields");
      return;
    }
    const result = await planStore.editPlan(id, { caption: caption, description: description, volume: volume });
    if (result) {
      navigate("/configuration/plan")
      toast.success("Plan edited successfully");
    };
  };

  return (
    <>
      <Modal
        title={"Edit Plan Details"}
        closeModal={() => {
          navigate("/configuration/plan");
        }}
      >

        <Form>
          <FormBody>
            <TextInput label={'Caption'} name="caption" value={caption}  onChange={(e) => setCaption(e.target.value)} />
            <TextInput label={'Description'} name="description"  value={description} onChange={(e) => setDescription(e.target.value)} />
            <TextInput label={'Volume'} name="volume"   value={volume} onChange={(e) => setVolume(e.target.value)} />
            <br />
            <button onClick={editplan}>Submit</button>
          </FormBody>
        </Form>
      </Modal>
    </>
  );
});

export default EditPlan