import { makeAutoObservable, runInAction } from "mobx";
import { callApi } from "../actions/apiMethods";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export class protocolStore{
  protocol = []

  constructor() {
		makeAutoObservable(this);
	}

	addProtocol = async (formEntries) => {
		const response = await callApi("POST", "api/protocol", formEntries);
		if (!response) return;
		runInAction(() => {
			this.protocol.push(formEntries);
			this.loading = false;
		});
		toast.success(response.message);
		return response.message;
	};

  fetchProtocol = async () => {
		const response = await callApi("GET", "api/protocol");
		if (!response) return;
		runInAction(() => {
			this.protocol = response.details;
		});
	};

	editProtocol = async (id, protocolInfo) => {
		const response = await callApi("PUT", `api/protocol/${id}`, protocolInfo);
		if (!response) return;
		runInAction(() => {
			this.protocol = this.protocol.map((provider) => (provider.id === id ? { ...provider, ...protocolInfo } : provider));
			this.loading = false;
		});
		toast.success(response.protocol);
		return response.protocol;
	};

	deleteProtocol = async (planId) => {
		const response = await callApi("DELETE", `api/protocol/${planId}`);
		if (!response) return;
		runInAction(() => {
			this.protocol = this.protocol.filter((provider) => provider.id !== planId);
			this.loading = false;
		});
		toast.success("Protocol deleted");
	};

}