import { makeAutoObservable, runInAction } from "mobx";
import { callApi } from "../actions/apiMethods";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export class planStore{
  serviceProvider = []
  plan = []
  protocol = []

  constructor() {
		makeAutoObservable(this);
	}

	addPlan = async (formEntries) => {
		const response = await callApi("POST", "api/plan", formEntries);
		if (!response) return;
		runInAction(() => {
			this.plan.push(formEntries);
			this.loading = false;
		});
		toast.success(response.message);
		return response.message;
	};

  fetchPlan = async () => {
		const response = await callApi("GET", "api/plan");
		if (!response) return;
		runInAction(() => {
			this.plan = response.details;
		});
	};

	editPlan = async (id, planInfo) => {
		const response = await callApi("PUT", `api/plan/${id}`, planInfo);
		if (!response) return;
		runInAction(() => {
			this.plan = this.plan.map((provider) => (provider.id === id ? { ...provider, ...planInfo } : provider));
			this.loading = false;
		});
		toast.success(response.plan);
		return response.plan;
	};

	deletePlan = async (planId) => {
		const response = await callApi("DELETE", `api/plan/${planId}`);
		if (!response) return;
		runInAction(() => {
			this.plan = this.plan.filter((provider) => provider.id !== planId);
			this.loading = false;
		});
		toast.success("Plan deleted");
	};

}