import {jwtDecode} from "jwt-decode";
import { token } from "../libs/localStorage";
import { redirect } from "react-router-dom";

export const isLoggedIn = () => {
  const localToken = token.get();
  if (!localToken) return false;
  // console.log({jwtDecode}(localToken))
  if (jwtDecode(localToken).exp > Date.now() / 1000) {
    return true;
  } else {
    token.remove();
    return false;
  }
};

export const checkRedirectToLogin = () => {
  if (!isLoggedIn()) return redirect("/login");
  return true;
};
