import React, { useState, useEffect } from "react";
import "../Details/details.css"
import { useContext } from "react";
import { RootContext } from "../../index";
import { observer } from "mobx-react-lite";
import { Outlet, useNavigate, useParams } from "react-router-dom";

const RenderDetails = observer(() => {
  const rootStore = useContext(RootContext);
  const deviceStore = rootStore.deviceStore;

  const { deviceId,id } = useParams();

  useEffect(() => {
    deviceStore.fetchdeviceById(id);
    deviceStore.fetchplan();
    deviceStore.fetchprotocol();
    deviceStore.fetchserviceProvider();
    // serverStore.fetchUserServers();
  }, []);

  return (
    <div>
    <div className="grid-container">
      <div className="grid-item">
      <h2>Device Details</h2>
      <p>Current Month Data Usage:10034 bytes</p>
      <p>Current Month Data Usage:10034 bytes</p>
      <p>Current Month Data Usage:10034 bytes</p>
      <p>Current Month Data Usage:10034 bytes</p>
      <p>Current Month Data Usage:10034 bytes</p>


      </div>
      <div className="grid-item">
        <h2>Total Monthly Data Usage</h2>
      <p>Current Month Data Usage:10034 bytes</p>
      <p>Previous Month Data Usage:10034 bytes</p>
      </div>
     
    </div>
  </div>
  )
})

export default RenderDetails