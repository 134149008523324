import { makeAutoObservable, runInAction } from "mobx";
import { callApi } from "../actions/apiMethods";
import { toast } from "react-toastify";

export class userStore{
  users = []
	user = []
	roles = []

  constructor() {
		makeAutoObservable(this);
	}

  fetchUsers = async () => {
		const response = await callApi("GET", "api/users");
		if (!response) return;
		runInAction(() => {
			this.users = response.details;
		});
	};


	registerUser = async (formEntries) => {
		const response = await callApi("POST", "api/auth/register", formEntries);
		if (!response) return;
		runInAction(() => {
			this.user.push(formEntries);
			this.loading = false;
		});
		// toast.success(response.token);
		return response.token;
	};

	deleteUsers = async (userId) => {
		const response = await callApi("DELETE", `api/users/${userId}`);
		if (!response) return;
		runInAction(() => {
			this.users = this.users.filter((user) => user.id !== userId);
			this.loading = false;
		});
		 toast.success("User deleted");
	};

	editUser = async (id, userInfo) => {
		try {
		const response = await callApi("PUT", `api/users/${id}`, userInfo);
		if (!response) return;
		runInAction(() => {
			this.users = this.users.map((user) => (user.id === id ? { ...user, ...userInfo } : user));
			this.loading = false;
		});
		// toast.success(response.success);
		return response.success;
		} catch (error) {
			console.log(error,"errror")
		}
		
	};

	fetchRoles = async () => {
		const response = await callApi("GET", "api/roles");
		if (!response) return;
		runInAction(() => {
			this.roles = response.details;
		});
	};


}