import React, { useState, useEffect } from "react";
import Modal from "../../Common/Modal";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../../..";
import { observer } from "mobx-react-lite";
import { DataTable } from "../../Common/DataTable/DataTable";
import { Outlet, useNavigate } from "react-router-dom";
import { Spinner } from "../../Common/Spinner";
import { DataTable3 } from "../../Server/DataTable3";
import Form, { FormBody, TextInput } from '../../Common/Form';

const Protocol = observer(() => {
    const rootStore = useContext(RootContext);
    const protocolStore = rootStore.protocolStore;

    const [showAddProtocol, setShowAddProtocol] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        if (!protocolStore.protocol.length) protocolStore.fetchProtocol();
    }, []);

    const [protocolInfo, setProtocolInfo] = useState({
        caption: "",
        description: "",
    });

    const setNewForm = (e) => {
        const { name, value } = e.target;
        setProtocolInfo((prev) => ({ ...prev, [name]: value }));
    };


    const addProtocol = async (e) => {
        e.preventDefault();
        if (!protocolInfo.caption || !protocolInfo.description) {
            toast.error("Missing fields");
            return;
        }
        const addedprotocolInfo = await protocolStore.addProtocol(protocolInfo);

        if (!addedprotocolInfo.id) {
            await protocolStore.fetchProtocol();

            setProtocolInfo({ caption: "", description: "" });
        }

        setShowAddProtocol(false)
    };

    const toggleCheckbox = (id) => {
        const isSelected = selectedRows.includes(id);

        if (isSelected) {
            setSelectedRows((prevRows) => prevRows.filter((rowId) => rowId !== id));
        } else {
            setSelectedRows((prevRows) => [...prevRows, id]);
        }
    };



    const handleCheckboxChange = (id) => {
        const isSelected = selectedRows.includes(id);

        if (isSelected) {
            setSelectedRows((prevRows) => prevRows.filter((rowId) => rowId !== id));
        } else {
            setSelectedRows((prevRows) => [...prevRows, id]);
        }

        handleCheckboxChange && handleCheckboxChange(selectedRows);
    };



    const deleteProtocol = async (id) => {
        const confirm = window.confirm("Delete?");
        if (selectedRows.length != 0 && confirm) {
            await Promise.all(selectedRows.map((id) => protocolStore.deleteProtocol(id)));
            setSelectedRows([]);
        } else if (confirm) {
            await protocolStore.deleteProtocol(id);
        }
    }


    return (
        <div>
            {protocolStore.loading ? (
                <Spinner />
            ) : (
                <DataTable3
                    title="Protocol List"
                    data={protocolStore.protocol
                        .map((item, index) => {
                            return {
                                ...item,
                                SN: index + 1,
                            }
                        })

                    }
                    headers={[
                        { key: "SN", label: "SN" },
                        { key: "caption", label: "Caption" },
                        { key: "description", label: "Description" }
                    ]}
                    handleAdd={() => setShowAddProtocol(true)}
                    handleEdit={(id) => {
                        navigate(`/configuration/protocols/${id}`);
                    }}
                    handleDelete={(e) => deleteProtocol(e)}
                    handleCheckboxChange={(id) => toggleCheckbox(id, selectedRows, setSelectedRows)}
                />)}

            {showAddProtocol && (
                <Modal
                    title={"Add Protocol"}
                    closeModal={() => {
                        setShowAddProtocol(false);
                        setProtocolInfo({ caption: "", description: "", volume: "" });
                    }}
                >

                    <Form>
                        <FormBody>
                            <TextInput label={'Caption'} name="caption" value={protocolInfo.caption} onChange={setNewForm} />
                            <TextInput label={'Description'} name="description" value={protocolInfo.description} onChange={setNewForm} />
                            <button onClick={(e) => addProtocol(e)}>Submit</button>
                        </FormBody>
                    </Form>
                </Modal>
            )}
            <Outlet />
        </div>

    );
});


export default Protocol