import React, { useContext, useEffect, useState } from 'react'
import "../MetaData/MetaData.css"
import Tags from '../Tags/Tags'
import Form, { FormBody, FormActions, TextInput, SelectInput } from '../Common/Form';
import { useParams, useNavigate } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { RootContext } from "../../index";
import { toast } from "react-toastify";
import { preferences, token } from '../../libs/localStorage';
import moment from 'moment'

const MetaData = observer(() => {
  const rootStore = useContext(RootContext);
  const deviceStore = rootStore.deviceStore;
  const simDetailStore = rootStore.simDetailStore
  const tagStore = rootStore.tagStore
  const uiStore = rootStore.uiStore

  const [preference,setPreference] = useState({
    'id': token.getDecoded(),
    'timezone': 'UTC',
  })

  useEffect(() => {
   let userPreferenceTime = preferences.get().timezone
   setPreference({
    timezone: userPreferenceTime
   })
}, []);

  const onChangeTimeZone = (e) => {
   setPreference({
    ...preference,
    timezone:e.target.value
   })

  }

  const allTimezones = moment.tz.names().map(item => ({
    value: item,
    label: `${item} (${moment.tz(item).zoneAbbr()})`
  }));

  const onSaveSettings = (e) => {
    e.preventDefault()

    preferences.set({
      timezone: preference.timezone,
    });
 
    toast.success('Settings Saved')
   }


  return (
    <div>
      <div className='inline-grid space-between' style={uiStore.isNavOpen  ? {} : {marginLeft:'10px'}}>
        <div>
          <Form>
            <FormBody>
            <SelectInput
              label='Timezone'
              value={preference.timezone} options={allTimezones} onChange={onChangeTimeZone}
              fields={{ label: 'label', value: 'value' }}
              displayLabel={<div>Timezone<i className='fa fa-question-circle' style={{ fontSize: '15px' }} data-toggle="tooltip" title="Time/Timestamp will be displayed in selected timezone in observation, monitoring, analysis and csv downloads." /></div>}
            />
            <button onClick={(e) => onSaveSettings(e)}>Submit</button>
            </FormBody>
          </Form>
        </div>             
      </div>
    </div>
  )
})

export default MetaData