import React, { useState, useEffect } from "react";
import Modal from "../Common/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../../index";
import { observer } from "mobx-react-lite";
import { Outlet, useNavigate } from "react-router-dom";
import { Spinner } from "../Common/Spinner";
import { DataTable3 } from "../Server/DataTable3";


const TagsList = observer(() => {
    const rootStore = useContext(RootContext);
    const tagStore = rootStore.tagStore;

    const [showAddTags, setShowAddTags] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);


    const navigate = useNavigate();

    useEffect(() => {
        tagStore.fetchTags();
    }, []);

    const [tagInfo, setTagsInfo] = useState({
        caption: "",
        description: "",
    });

    const setNewForm = (e) => {
        const { name, value } = e.target;
        setTagsInfo((prev) => ({ ...prev, [name]: value }));
    };

    const addTags = async (e) => {
        e.preventDefault();
        if (!tagInfo.caption || !tagInfo.description) {
            toast.error("Missing fields");
            return;
        }
        const addedtagInfo = await tagStore.postTags(tagInfo);
        console.log(addedtagInfo,"addedTagsInfo");

         if (addedtagInfo.id) {
             await tagStore.fetchTags();
         }
            setTagsInfo({ caption: "", description: "" });
    
        setShowAddTags(false)
    };


    const deleteSelectedPlans = async (id) => {
        const confirm = window.confirm("Delete?");
        if (selectedRows.length != 0 && confirm) {
            await Promise.all(selectedRows.map((id) => tagStore.deleteTag(id)));
            setSelectedRows([]);
        } else if (confirm) {
            await tagStore.deleteTag(id);
        }
    }



    const toggleCheckbox = (id) => {
        const isSelected = selectedRows.includes(id);

        if (isSelected) {
            setSelectedRows((prevRows) => prevRows.filter((rowId) => rowId !== id));
        } else {
            setSelectedRows((prevRows) => [...prevRows, id]);
        }
    };

    const handleCheckboxChange = (id) => {
        const isSelected = selectedRows.includes(id);

        if (isSelected) {
            setSelectedRows((prevRows) => prevRows.filter((rowId) => rowId !== id));
        } else {
            setSelectedRows((prevRows) => [...prevRows, id]);
        }

        handleCheckboxChange && handleCheckboxChange(selectedRows);
    };

    return (
        <div>
            <DataTable3
                data={tagStore.tags
                    .map((item, index) => {
                        return {
                            ...item,
                            SN: index + 1,
                        }
                    })

                }
                headers={[
                    { key: "SN", label: "SN" },
                    { key: "caption", label: "Caption" },
                    { key: "description", label: "Description" },
                    // { key: "volume", label: "Volume(kb)" }
                ]}
                handleAdd={() => setShowAddTags(true)}
                // handleEdit={(id) => {
                //     navigate(`/configuration/plan/${id}`);
                // }}
                handleDelete={(e) => deleteSelectedPlans(e)}
                handleCheckboxChange={(id) => toggleCheckbox(id, selectedRows, setSelectedRows)}
            />

            {showAddTags && (
                <Modal
                    title={"Add Tag"}
                    closeModal={() => {
                        setShowAddTags(false);
                        setTagsInfo({ caption: "", description: "" });
                    }}
                >
                    <form className="Form">
                        <label>Caption</label>
                        <input
                            type="text"
                            name="caption"
                            value={tagInfo.caption}
                            onChange={setNewForm}
                        />

                        <label>Description</label>
                        <input
                            type="text"
                            name="description"
                            value={tagInfo.description}
                            onChange={setNewForm}
                        />
                        <br />
                        <button onClick={(e) => addTags(e)}>Submit</button>
                    </form>
                </Modal>
            )}
            <Outlet />
        </div>

    );
});


export default TagsList
