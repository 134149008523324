import React, { useState } from "react";
import "../../Common/DataTable/DataTable.css";
import { TablePagination} from "@mui/material";
import {useNavigate } from "react-router-dom";

export const DataTable5 = ({
  headers,
  data,
  handleAdd,
  handleEdit,
  handleDelete,
  title,
  onRowClick,
  handleCheckboxChange,
}) => {
  
  const navigate = useNavigate()
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5); 
  const [page, setPage] = useState(0);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const toggleCheckbox = (id) => {
    const isSelected = selectedRows.includes(id);
    if (isSelected) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const isRowSelected = (id) => selectedRows.includes(id);

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedRows(selectAll ? [] : data.map((item) => item.id));
  };

  const pageRedirect = (e) => {
    navigate(`/singledevicedetail/sim/metadata/${e}`);
    }

  return (
    <div className="data-table-container">
      <div style={{display:'flex', justifyContent:"space-between"}}>
      {title && <h1>{title}</h1>}
      <TablePagination
        rowsPerPageOptions={[5, 15, 25,50,100]}
        component="div"
        count={data ? data.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      
      <table className="data-table">
        <thead>
          <tr>
            <th style={{ width: 10 }}>
						<input
                type="checkbox"
                checked={selectAll}
                onChange={toggleSelectAll}
              />
						</th>
            {headers.map((title, index) => (
              <th key={index}  style={{ width: title.width }}>{title.label}</th>
            ))}
            <th>
              {handleAdd && (
                <span className="actions">
                  <i className="fa fa-plus" onClick={handleAdd}></i>
                </span>
              )}
            </th>
          </tr>
        </thead>

        <tbody>
          {data &&
            data
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((data, index) => (
  
              <tr style={{cursor:"pointer"}} key={index}>
                
                <td>
                  <input
                    type="checkbox"
                    checked={isRowSelected(data.id)}
                    onChange={() => {
                      toggleCheckbox(data.id);
                      handleCheckboxChange && handleCheckboxChange(data.id);
                    }}
                  />
                </td>
                {headers.map((h, i) => (
                  <td style={{ padding: "5px" }} key={i}>
                    {data[h.key]}
                  </td>
                ))}
                <td className="action-icons">
                  {handleEdit && (
                    <i
                      className="fa fa-pencil"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleEdit(data.id, data);
                      }}
                    />
                  )}
                  {handleDelete && (
                    <i
                      className="fa fa-trash"
                      style={{ cursor: "pointer", paddingLeft: "5px" }}
                      onClick={() => {
                        handleDelete(data.id);
                      }}
                    />
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
     
    </div>
  );
};
