import { makeAutoObservable, runInAction } from "mobx";
import { callApi } from "../actions/apiMethods";
import { toast } from "react-toastify";

export class rechargeStore{
	recharge = []
	toggle = []
	credits = []
	rechargeForm = []

  constructor() {
		makeAutoObservable(this);
	}


  fetchRechargeList = async () => {
		const response = await callApi("GET", "api/rechargelist");
		if (!response) return;
		runInAction(() => {
			this.recharge = response.details;
      this.loading = false;
		});
	};

	fetchCredits = async () => {
		const response = await callApi("GET", "api/credits");
		if (!response) return;
		runInAction(() => {
			this.credits = response.details;
      this.loading = false;
		});
	};

	toggleRecharge = async (formEntries) => {
	  const response = await callApi("PUT", "api/toggle", formEntries);
		if (!response) return;
		runInAction(() => {
			this.toggle.push(formEntries);
			this.loading = false;
		});
		toast.success(response.message);
		return response.message;
	};

	deleteRecharge = async (id) => {
		const response = await callApi("DELETE", `api/credits/${id}`);
		if (!response) return;
		runInAction(() => {
			this.credits = this.credits.filter((credit) => credit.id !== id);
			this.loading = false;
		});
		toast.success("Deleted");
	};

	addRechargeForm = async (formEntries) => {
		const response = await callApi("POST", "api/rechargeform", formEntries);
		if (!response) return;
		runInAction(() => {
			this.rechargeForm.push(formEntries);
			this.loading = false;
		});
		toast.success(response.message);
		return response.message;
	};

}