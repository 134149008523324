import React from "react";
import ReactModal from "react-modal";
import "./ServerModal.css"

const styles = {
	modal: {
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: "rgba(0, 0, 0, 0.3)",
			zIndex: 1000
		},
		content: {
			backgroundColor: "white",
			position: "fixed",
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)"
		}
	}
};

const ServerModal = ({ children, title, closeModal }) => {
	const onRequestClose = (e) => {
		closeModal();
	};

	return (
		<ReactModal
			isOpen
			onRequestClose={onRequestClose}
			className="ServerModal"
			style={styles.modal}
			contentLabel={title}
			appElement={document.getElementById("root")}
		>
			<div className="modalHeader">
				<div className="modalHeaderTitle">{title}</div>
				<span
					className="modalHeaderClose"
					onClick={() => closeModal()}
				>
					<i className="fa fa-close" />
				</span>
			</div>
			<div className="modalChildren">{children}</div>
		</ReactModal>
	);
};

export default ServerModal;
