import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';

const Graph = ({ graphData }) => {
  graphData = graphData.sort((a, b) => new Date(a.name) - new Date(b.name))

  if (graphData.length === 0) {
    return <div style={{ color: "red", marginLeft: "700px", marginTop: "300px" }}>No Data</div>;
  }
  
  const dataSteps = () => {
    if (graphData.length > 50 && graphData.length < 100) {
      return 15
    } else if (graphData.length > 100 && graphData.length < 200) {
      return 20
    }  
    else if (graphData.length > 200) {
      return Math.ceil(graphData.length / 5);
    }   
    else {
      return 4
    }
  }

  const options = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Data Usage',
    },
    subtitle: {
      text: 'OrbComm',
    },
    xAxis: {
      type: 'category',
      labels: {
        rotation: 0,
        style: {
          fontSize: '13px',
          fontFamily: 'Verdana, sans-serif'
        },
        step: dataSteps(),
        formatter: function () {
          const formattedLabel = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(this.value)
            ? moment.utc(this.value).startOf('hours').format('ddd hh:mm a')
            : moment.utc(this.value).startOf('day').format('YYYY-MM-DD');
          return formattedLabel;
        }
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Data Usage (Bytes)',
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {

        if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(this.point.name)) {
          const startTime = moment.utc(this.point.name).startOf('hours').format('YYYY-MM-DD HH:mm:ss');
          const endTime = moment.utc(this.point.name).add(1, 'hours').startOf('hours').format('YYYY-MM-DD HH:mm:ss')

          return `<b>${startTime} to ${endTime}</b><br/>Data Usage: ${Highcharts.numberFormat(this.point.y, 0)} bytes`;
        } else {
          const startTime = moment.utc(this.point.name).startOf('day').format('YYYY-MM-DD HH:mm:ss');
          const endTime = moment.utc(this.point.name).add(1, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss');
          return `<b>${startTime} to ${endTime}</b><br/>Data Usage: ${Highcharts.numberFormat(this.point.y, 0)} bytes`

        }

      },
      shared: true
    },
    series: [
      {
        name: 'Orbcomm',
        colors: ['#277dbd'],
        colorByPoint: true,
        groupPadding: 0,
        data: graphData,
      },
    ],
  };


  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default Graph;
