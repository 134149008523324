import React, { useState, useEffect } from "react";
import Modal from "../Common/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../../index";
import { observer } from "mobx-react-lite";
// import { DataTable } from "../../Common/DataTable/DataTable";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { Spinner } from "../Common/Spinner";
import { simDetailStore } from "../../stores/simDetail_store";
import moment from "moment"
import { SimDetailDataTable } from "./SimDetailDataTable";
require ("moment-timezone");

const SingleSimDetail = observer(() => {
  const rootStore = useContext(RootContext);
  const simDetailStore = rootStore.simDetailStore;
  const deviceStore = rootStore.deviceStore;

  const [showAddEmail, setShowAddEmail] = useState(false)
  const [simDetail, setSimDetails] = useState([])
  const [balance, setBalance] = useState("");
  const [number, setNumber] = useState("");
  const [volume, setVolume] = useState("");
  const [showRegisterEmail, setShowRegisterEmail] = useState(false)
  const [simId, setSimId] = useState([])
  const [emailDetail, setEmailDetail] = useState([])



  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      await deviceStore.fetchDeviceByDeviceId(id);
      await simDetailStore.fetchSimDetailsByNum(id);
      await simDetailStore.fetchEmailDetailByDeviceId(id)
      const simDetail = simDetailStore.details2;
      const deviceDetail = deviceStore.deviceByDeviceIdDetails
      const email = simDetailStore.emails
      
      setNumber(simDetail[0]?.phonenumber)
      setBalance(simDetail[0]?.remaining_balance)
      setVolume(simDetail[0]?.remaining_volume)
      setSimId(deviceDetail[0]?.id)
      setEmailDetail(email[0]?.email)
    };
    fetchData();


  }, []);

  useEffect(()=> {

  })

  const [emailInfo, setEmailInfo] = useState({
    email: "",
  });

  const setNewForm = (e) => {
    const { name, value } = e.target;
    setEmailInfo((prev) => ({ ...prev, [name]: value }));
  };

  const addEmail = async (e) => {
    if (!emailInfo.email) {
      toast.error("Missing fields");
      return;
    }
    let email = emailInfo.email
 
    const addEmail = await simDetailStore.createEmail({ email, simId,device_id:id });

    if (addEmail) {
      toast.success("Email added");
    }
    setShowRegisterEmail(false)
  }

  const sendEmail = async (e) => {
    e.preventDefault();

    let email = emailDetail
    const result = await simDetailStore.postEmail({ volume, number, email, balance });

    if (result) {
      toast.success("Email sent");
    }

    setShowAddEmail(false)
  }

  return (
    <div>
      {simDetailStore.loading ? (
        <Spinner />
      ) : (
        <SimDetailDataTable
          data={simDetailStore.details2
            .slice().sort((a, b) => b.id - a.id)
            .map((item, index) => {
              const balanceAlert = {
                color: parseFloat(item.remaining_balance) < 5 ? 'red' : 'inherit',
                borderRadius: "20px"
              };

              const volumeAlert = {
                color: parseInt(item.remaining_volume) < 50 ? 'red' : 'inherit',
                borderRadius: "20px",

              };

              return {
                ...item,
                SN: index + 1,
                created_date: moment.utc(item.created_date).tz('Asia/Kathmandu').format("YYYY-MM-DD HH:mm:ss"),
                balance: <tr style={balanceAlert}>{item.remaining_balance}</tr>,
                remaining_volume: <tr style={volumeAlert}>{item.remaining_volume.replace("Rs.", "")}</tr>
              }
            })

          }
          headers={[
            { key: "SN", label: "SN" },
            { key: "phonenumber", label: "Number" },
            { key: "created_date", label: "Query Date" },
            { key: "provider", label: "Provider" },
            { key: "balance", label: "Balance" },
            { key: "remaining_volume", label: "Remaining Volume" }
          ]}
          handleAdd={() => setShowRegisterEmail(true)}
          handleSendEmail={() => setShowAddEmail(true)}
        />)}

      {showRegisterEmail && (
        <Modal
          title={"Add Email"}
          closeModal={() => {
            setShowRegisterEmail(false);
            setEmailInfo({ email: "" });
          }}
        >
          <form className="Form">
            <label>Email</label>
            <input
              type="text"
              name="email"
              value={emailInfo.email}
              onChange={setNewForm}
            ></input>

            <button onClick={(e) => addEmail(e)}>Submit</button>
          </form>
        </Modal>
      )}

      {showAddEmail && (
        <Modal
          title={"Send Email"}
          closeModal={() => {
            setShowAddEmail(false);
            setEmailInfo({ email: "", balance: "", volume: "" });
          }}
        >
          <form className="Form">
            <label>Sim Number</label>
            <input
              type="text"
              name="simnum"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
            />
            <label>Email</label>
            <input
              type="text"
              name="email"
              value={emailDetail}
              onChange={(e) => setEmailDetail(e.target.value)}
            />

            <label>Balance</label>
            <input
              type="text"
              name="balance"
              value={balance}
              onChange={(e) => setBalance(e.target.value)}
            />

            <label>Volume</label>
            <input
              type="text"
              name="volume"
              value={volume}
              onChange={(e) => setVolume(e.target.value)}
            />
            <br />
            <button onClick={(e) => sendEmail(e)}>Submit</button>
          </form>
        </Modal>
      )}
      <Outlet />
    </div>

  );
});


export default SingleSimDetail
