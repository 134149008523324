import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../../../src/index";
import { toast } from "react-toastify";
import { token } from "../../libs/localStorage";
import "./RechargeForm.css"

const RechargeForm = () => {
  const rootStore = useContext(RootContext);
  const rechargeStore = rootStore.rechargeStore;
  const decodedToken = token.getDecoded();

  const [rechargeForm, setRechargeForm] = useState({
    email: "",
    customer: "",
    project: "",
    station_name: "",
    device_code: "",
    servers: [],
    service_provider: "",
    network: "",
    sim_number: "",
    recharge_amount: "",
    station_status: "",
  });

  const setNewForm = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setRechargeForm((prev) => {
        const servers = prev.servers.includes(value)
          ? prev.servers.filter((server) => server !== value)
          : [...prev.servers, value];
        return { ...prev, servers };
      });
    } else {
      setRechargeForm((prev) => ({ ...prev, [name]: value }));
    }
  };

  const addForm = async (e) => {
    e.preventDefault();
    console.log(rechargeForm, "rechargeForm");

    if (!rechargeForm.email || !rechargeForm.customer || !rechargeForm.project || !rechargeForm.station_name || !rechargeForm.device_code || !rechargeForm.servers || !rechargeForm.service_provider || !rechargeForm.network || !rechargeForm.sim_number || !rechargeForm.recharge_amount || !rechargeForm.station_status) {
      toast.error("Missing fields");
      return;
    }
    const addedrechargeForm = await rechargeStore.addRechargeForm(rechargeForm);
    console.log(addedrechargeForm, "addedrechargeForm");

    if (!addedrechargeForm.id) {
      // await planStore.fetchPlan();

      setRechargeForm({
        email: "",
        customer: "",
        project: "",
        station_name: "",
        device_code: "",
        servers: [],
        service_provider: "",
        network: "",
        sim_number: "",
        recharge_amount: "",
        station_status: "",
      });
    }

  };
// console.log(rechargeForm.servers,"rechargeForm.servers");

  return (
    <div className="recharge-form">
  <div class="p-6 bg-gray-100 flex items-center justify-center">
      <div class="container max-w-screen-lg mx-auto">
        <div>
          <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3 pl-40">
              <div class="lg:col-span-2">
                <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                  <div class="md:col-span-5">
                    <label for="email">Email</label>
                    <input type="text" name="email" id="email" class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={rechargeForm.email} placeholder="email@domain.com" onChange={(e) => setNewForm(e)} />
                  </div>

                  <div class="md:col-span-5">
                    <label for="customer">Customer</label>
                    <input type="text" name="customer" id="customer" class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={rechargeForm.customer} onChange={(e) => setNewForm(e)} />
                  </div>



                  <div class="md:col-span-3">
                    <label for="project">Project</label>
                    <input type="text" name="project" id="project" class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={rechargeForm.project} placeholder="" onChange={(e) => setNewForm(e)} />
                  </div>

                  <div class="md:col-span-2">
                    <label for="station">Station Name</label>
                    <input type="text" name="station_name" id="station_name" class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={rechargeForm.station_name} placeholder="" onChange={(e) => setNewForm(e)} />
                  </div>

                  <div class="md:col-span-5">
                    <label for="devicecode">Device Code</label>
                    <input type="text" name="device_code" id="device_code" class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={rechargeForm.device_code} onChange={(e) => setNewForm(e)} />
                  </div>

                  <div className="md:col-span-5">
                    <label htmlFor="servers">Servers (Post 1/Post 2/Poll)</label>
                    <div className="flex flex-col mt-1">
                      <label className="inline-flex items-center">
                        <input type="checkbox" className="form-checkbox h-5 w-5 text-gray-600" value="daq.wscada.net" onChange={setNewForm} checked={rechargeForm.servers.includes("daq.wscada.net")} />
                        <a href='https://daq.wscada.net' className='ml-2 text-blue-500 hover:underline'>daq.wscada.net</a>
                      </label>
                      <label className="inline-flex items-center mt-2">
                        <input type="checkbox" className="form-checkbox h-5 w-5 text-gray-600" value="daq.hydrology.gov.np" onChange={setNewForm} checked={rechargeForm.servers.includes("daq.hydrology.gov.np")} />
                        <a href='https://daq.hydrology.gov.np' className='ml-2 text-blue-500 hover:underline'>daq.hydrology.gov.np</a>
                      </label>
                      <label className="inline-flex items-center mt-2">
                        <input type="checkbox" className="form-checkbox h-5 w-5 text-gray-600" value="www.hydrology.gov.np" onChange={setNewForm} checked={rechargeForm.servers.includes("www.hydrology.gov.np")} />
                        <a href='http://www.hydrology.gov.np' className='ml-2 text-blue-500 hover:underline'>www.hydrology.gov.np</a>
                      </label>
                      <label className="inline-flex items-center mt-2">
                        <input type="checkbox" className="form-checkbox h-5 w-5 text-gray-600" value="test.wscada.net" onChange={setNewForm} checked={rechargeForm.servers.includes("test.wscada.net")} />
                        <a href='http://test.wscada.net' className='ml-2 text-blue-500 hover:underline'>test.wscada.net</a>
                      </label>
                      <label className="inline-flex items-center mt-2">
                        <input type="checkbox" className="form-checkbox h-5 w-5 text-gray-600" value="other" onChange={setNewForm} checked={rechargeForm.servers.includes("other")} />
                        <a href='other' className='ml-2 text-blue-500 hover:underline'>Other</a>
                        <input type="text" name="servers" id="servers" className="h-10 border-b-2 border-gray-300 ml-2 w-full bg-transparent focus:outline-none" placeholder="Please specify"  onChange={setNewForm} />
                      </label>
                    </div>
                  </div>
                  <div class="md:col-span-5" >
                    <label for="serviceprovider">Service Provider</label>
                    <select name="service_provider" id="service_provider" class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={rechargeForm.service_provider} onChange={(e) => setNewForm(e)}>
                      <option value="">Select a service provider</option>
                      <option value="NTC">NTC</option>
                      <option value="NCELL">NCELL</option>
                      <option value="SmartTel">SmartTel</option>
                      <option value="UTL">UTL</option>
                      <option value="Thuraya">Thuraya</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>

                  <div class="md:col-span-5">
                    <label for="network">Network</label>
                    <select name="network" id="network" class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={rechargeForm.network} onChange={(e) => setNewForm(e)}>
                      <option value="">Select Network</option>
                      <option value="GSM">GSM</option>
                      <option value="CDMA">CDMA</option>
                      <option value="Satellite">Satellite</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>

                  <div class="md:col-span-3">
                    <label for="number">Sim Number</label>
                    <input type="text" name="sim_number" id="sim_number" class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={rechargeForm.sim_number} onChange={(e) => setNewForm(e)} />
                  </div>

                  <div class="md:col-span-2">
                    <label for="amount">Recharge Amount</label>
                    <input type="text" name="recharge_amount" id="recharge_amount" class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={rechargeForm.recharge_amount} onChange={(e) => setNewForm(e)} />
                  </div>

                  <div class="md:col-span-5">
                    <label for="status">Station Status</label>
                    <select name="station_status" id="station_status" class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={rechargeForm.station_status} onChange={(e) => setNewForm(e)}>
                      <option value="">Select Status</option>
                      <option value="under-warranty">Installed (Under Warranty)</option>
                      <option value="under-AMC">Installed (Under AMC)</option>
                      <option value="fat-testing">FAT Testing</option>
                      <option value="RnD-testing">RnD Testing</option>
                      <option value="other">Other</option>
                    </select>
                  </div>

                  <div class="md:col-span-5 text-right">
                    <div class="inline-flex items-end">
                      <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={(e) => addForm(e)}>Submit</button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  
  )
}

export default RechargeForm