import React, { useState, useEffect } from "react";
import "../Details/details.css"
import { useContext } from "react";
import { RootContext } from "../../index";
import { observer } from "mobx-react-lite";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import DataUsage from "../DataUsage/dataUsage";
import RenderDetails from "./renderDetails";

const details = observer(() => {
  const rootStore = useContext(RootContext);
  const deviceStore = rootStore.deviceStore;

  const navigate = useNavigate()
  const { deviceId, id } = useParams();
  const [currentView, setCurrentView] = useState('details');

  useEffect(() => {
    deviceStore.fetchdeviceById(id);
    deviceStore.fetchplan();
    deviceStore.fetchprotocol();
    deviceStore.fetchserviceProvider();
    // serverStore.fetchUserServers();
  }, []);

  const getPlanValue = (planId) => {
    const value = deviceStore.plan.find((plan) => plan.id == planId)
    return value ? value.volume : ""
  }

  const getProtocolValue = (protocolId) => {
    const value = deviceStore.protocol.find((protocol) => protocol.id == protocolId)
    return value ? value.caption : ""
  }

  const getServiceProvider = (providerId) => {
    const value = deviceStore.serviceProvider.find((protocol) => protocol.id == providerId)
    return value ? value.caption : ""
  }




  const idData = deviceStore.deviceDetailsById
    .map((item, index) => {
      return {
        ...item,
        plan_id: getPlanValue(item.plan_id),
        protocol_id: getProtocolValue(item.protocol_id),
        service_provider_id: getServiceProvider(item.service_provider_id)
      }
    })

  const renderDetails = () => (
    <RenderDetails />
  );

  const renderDataUsage = () => (
    <DataUsage deviceId={deviceId} />
  )


  return (
    <div className='details-container'>
      <div className='details-header'>
        DeviceId-{idData[0] && idData[0].device_id}

      </div>

      {idData[0] && (
        <div className='box'>
          <nav className="details-navbar">
            <button onClick={() => setCurrentView('details')}>Details</button>
            <button onClick={() => setCurrentView('dataUsage')}>Data Usage</button>
          </nav>
          {currentView === 'details' ? renderDetails() : null}
          {currentView === 'dataUsage' ? renderDataUsage() : null}
        </div>
      )}

    </div>

  )
})

export default details