import React, { useState, useEffect } from "react";
import Modal from "../Common/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../../index";
import { observer } from "mobx-react-lite";
// import { DataTable } from "../../Common/DataTable/DataTable";
import { Outlet, useNavigate } from "react-router-dom";
import { Spinner } from "../Common/Spinner";
import { DataTable3 } from "../Server/DataTable3";
import { simDetailStore } from "../../stores/simDetail_store";
import moment from "moment"

const SimDetails = observer(() => {
    const rootStore = useContext(RootContext);
    const simDetailStore = rootStore.simDetailStore;

    const [showAddPlan, setShowAddPlan] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);


    const navigate = useNavigate();

    useEffect(() => {
        simDetailStore.fetchSimDetails();
    }, []);

    const [planInfo, setPlanInfo] = useState({
        phonenumber: "",
        puk1: "",
        pin1:"",
        purchasedate:"",
        provider:"",
        balance:"",
        remaining_volume:""
    });

    const setNewForm = (e) => {
        const { name, value } = e.target;
        setPlanInfo((prev) => ({ ...prev, [name]: value }));
    };


    // const addPlan = async (e) => {
    //     e.preventDefault();
    //     if (!planInfo.caption || !planInfo.description || !planInfo.volume) {
    //         toast.error("Missing fields");
    //         return;
    //     }
    //     const addedPlanInfo = await planStore.addPlan(planInfo);

    //     if (!addedPlanInfo.id) {
    //         await planStore.fetchPlan();

    //         setPlanInfo({ caption: "", description: "" });
    //     }

    //     setShowAddPlan(false)
    // };


    // const deleteSelectedPlans = async () => {
    //     const confirm = window.confirm("Delete selected plans?");
    //     if (confirm) {
    //       await Promise.all(selectedRows.map((id) => planStore.deletePlan(id)));
    //       setSelectedRows([]); 
    //     }
    //   };

    //   const toggleCheckbox = (id) => {
    //     const isSelected = selectedRows.includes(id);
        
    //     if (isSelected) {
    //       setSelectedRows((prevRows) => prevRows.filter((rowId) => rowId !== id));
    //     } else {
    //       setSelectedRows((prevRows) => [...prevRows, id]);
    //     }
    //   };
      


    //   const handleCheckboxChange = (id) => {
    //     const isSelected = selectedRows.includes(id);
      
    //     if (isSelected) {
    //       setSelectedRows((prevRows) => prevRows.filter((rowId) => rowId !== id));
    //     } else {
    //       setSelectedRows((prevRows) => [...prevRows, id]);
    //     }
      
    //     handleCheckboxChange && handleCheckboxChange(selectedRows);
    //   };
      
    return (
        <div>
            {simDetailStore.loading ? (
                <Spinner />
            ) : (
                <DataTable3
                    title="Plan List"
                    data={simDetailStore.details
                        .map((item, index) => {
                            return {
                                ...item,
                                SN: index + 1,
                                purchasedate:moment.utc(item.purchasedate).format("YYYY-MM-DD HH:mm:ss")
                            }
                        })

                    }
                    headers={[
                        { key: "SN", label: "SN" },
                        { key: "phonenumber", label: "Number" },
                        { key: "puk1", label: "PUK" },
                        { key: "pin1", label: "PIN" },
                        { key: "purchasedate", label: "Purchase Date" },
                        { key: "provider", label: "Provider" },
                        { key: "balance", label: "Balance" },
                        { key: "remaining_volume", label: "Remaining Volume" }
                    ]}
                    // handleAdd={() => setShowAddPlan(true)}
                    // handleEdit={(id) => {
                    //     navigate(`/configuration/plan/${id}`);
                    // }}
                    // handleDelete={deleteSelectedPlans}
                    // handleCheckboxChange={(id) => toggleCheckbox(id, selectedRows, setSelectedRows)}
                />)}

            {/* {showAddPlan && (
                <Modal
                    title={"Add Plan"}
                    closeModal={() => {
                        setShowAddPlan(false);
                        setPlanInfo({ caption: "", description: "", volume:"" });
                    }}
                >
                    <form className="Form">
                        <label>Caption</label>
                        <input
                            type="text"
                            name="caption"
                            value={planInfo.caption}
                            onChange={setNewForm}
                        />

                        <label>Description</label>
                        <input
                            type="text"
                            name="description"
                            value={planInfo.description}
                            onChange={setNewForm}
                        />

                        <label>Volume(kB)</label>
                        <input
                            type="text"
                            name="volume"
                            value={planInfo.volume}
                            onChange={setNewForm}
                        />
                        <br />
                        <button onClick={(e) => addPlan(e)}>Submit</button>
                    </form>
                </Modal>
            )} */}
            <Outlet />
        </div>

    );
});


export default SimDetails
