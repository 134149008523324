import { makeAutoObservable, runInAction } from "mobx";
import { callApi } from "../actions/apiMethods";
import { toast } from "react-toastify";

export class deviceStore{
  items = []
	items2 = []
	min = []
	device = []
	deviceDetailsById = []
	plan = []
	protocol = []
	serviceProvider = []
	gateWayAccount = []
	deviceIdDetails=[]
	deviceByDeviceIdDetails=[]
	deviceTags = []
	deviceList = []
	account = []
	folders = []
	scraperlogs = []
	scraperlog = []


  constructor() {
		makeAutoObservable(this);
	}

	addDevice2Server = async (formEntries) => {
		const response = await callApi("POST", "api/device2server", formEntries);
		if (!response) return;
		runInAction(() => {
			this.items.push(formEntries);
			this.loading = false;
		});
		toast.success(response.message);
		return response.details;
	};

  fetchdevice2server = async () => {
		const response = await callApi("GET", "api/device2server");
		if (!response) return;
		runInAction(() => {
			this.items = response.details;
		});
	};

	editDevice2Server = async (id, divInfo) => {
		const response = await callApi("PUT", `api/device2server/${id}`, divInfo);
		if (!response) return;
		runInAction(() => {
			this.items = this.items.map((div) => (div.id === id ? { ...div, ...divInfo } : div));
			this.loading = false;
		});
		toast.success(response.message);
		return response.message;
	};

	deleteDevice2 = async (deviceId) => {
		const response = await callApi("DELETE", `api/device2server2/${deviceId}`);
		if (!response) return;
		runInAction(() => {
			this.items = this.items.filter((device) => device.id !== deviceId);
			this.loading = false;
		});
		// toast.success("Device deleted");
	};
	

	deleteDevice2ServerByDeviceId = async (deviceId) => {
		const response = await callApi("DELETE", `api/deviceId/${deviceId}`);
		if (!response) return;
		runInAction(() => {
			this.items = this.items.filter((device) => device.id !== deviceId);
			this.loading = false;
		});
		toast.success("Device deleted");
	};

	fetchdevice = async () => {
		const response = await callApi("GET", "api/device");
		if (!response) return;
		runInAction(() => {
			this.device = response.details;
		});
	};

	fetchDeviceList = async () => {
		const response = await callApi("GET", "api/devicelist");
		if (!response) return;
		runInAction(() => {
			this.deviceList = response.details;
		});
	};

	fetchdeviceTags = async () => {
		const response = await callApi("GET", "api/devicetags");
		if (!response) return;
		runInAction(() => {
			this.deviceTags = response.details;
		});
	};


	fetchdeviceByIds = async (id) => {
		const response = await callApi("GET", `api/deviceId/${id}`);
		if (!response) return;
		runInAction(() => {
			this.deviceIdDetails = response.details;
		});
		return response.details
	};

	fetchDeviceByDeviceId = async (id) => {
		const response = await callApi("GET", `api/devicebydeviceid/${id}`);
		if (!response) return;
		runInAction(() => {
			this.deviceByDeviceIdDetails = response.details;
		});
		return response.details
	};

	editDevice = async (id, divInfo) => {
		try {
		const response = await callApi("PUT", `api/device/${id}`, divInfo);
		if (!response) return;
		runInAction(() => {
			this.device = this.device.map((div) => (div.id === id ? { ...div, ...divInfo } : div));
			this.loading = false;
		});
		// toast.success(response.message);
		return response.message;
		} catch (error) {
			console.log(error,"errror")
		}
		
	};

	deleteDevice = async (deviceId) => {
		const response = await callApi("DELETE", `api/device/${deviceId}`);
		if (!response) return;
		runInAction(() => {
			this.device = this.device.filter((device) => device.id !== deviceId);
			this.loading = false;
		});
		return response.details;
		// toast.success("Device deleted");
		//
	};

	addDevice = async (formEntries) => {
		const response = await callApi("POST", "api/device", formEntries);
		if (!response) return;
		runInAction(() => {
			this.device.push(formEntries);
			this.loading = false;
		});
		// toast.success(response.message);
		return response.details;
	};

	fetchdeviceById = async (deviceid) => {
		const response = await callApi("GET", `api/device/${deviceid}`);
		if (!response) return;
		runInAction(() => {
			this.deviceDetailsById = response.details;
		});
	};

	fetchplan = async () => {
		const response = await callApi("GET", "api/plan");
		if (!response) return;
		runInAction(() => {
			this.plan = response.details;
		});
	};

	fetchprotocol = async () => {
		const response = await callApi("GET", "api/protocol");
		if (!response) return;
		runInAction(() => {
			this.protocol = response.details;
		});
	};

	addFolder = async (formEntries) => {
		const response = await callApi("POST", "api/folders", formEntries);
		if (!response) return;
		runInAction(() => {
			this.folders.push(formEntries);
			this.loading = false;
		});
		toast.success(response.message);
		return response.details;
	};


	fetchfolders = async () => {
		const response = await callApi("GET", "api/folders");
		if (!response) return;
		runInAction(() => {
			this.folders = response.details;
		});
	};

	deleteFolder = async (deviceId) => {
		const response = await callApi("DELETE", `api/folders/${deviceId}`);
		if (!response) return;
		runInAction(() => {
			this.folders = this.folders.filter((folders) => folders.id !== deviceId);
			this.loading = false;
		});
		toast.success("Folder deleted");
	};

	addAccount = async (formEntries) => {
		const response = await callApi("POST", "api/account", formEntries);
		if (!response) return;
		runInAction(() => {
			this.account.push(formEntries);
			this.loading = false;
		});
		toast.success(response.message);
		return response.details;
	};


	fetchAccount = async () => {
		const response = await callApi("GET", "api/account");
		if (!response) return;
		runInAction(() => {
			this.account = response.details;
		});
	};

	deleteAccount = async (accountid) => {
		const response = await callApi("DELETE", `api/account/${accountid}`);
		if (!response) return;
		runInAction(() => {
			this.account = this.account.filter((account) => account.id !== accountid);
			this.loading = false;
		});
		// toast.success("Device deleted");
	};


	fetchserviceProvider = async () => {
		const response = await callApi("GET", "api/serviceProvider");
		if (!response) return;
		runInAction(() => {
			this.serviceProvider = response.details;
		});
	};

	addDataToDevice = async (formEntries) => {
		const response = await callApi("POST", "api/datatodevice", formEntries);
		if (!response) return;
		runInAction(() => {
			this.items2.push(formEntries);
			this.loading = false;
		});
		toast.success(response.message);
		return response.details;
	};

	fetchDataToDevice = async () => {
		const response = await callApi("GET", "api/datatodevice");
		if (!response) return;
		runInAction(() => {
			this.items2 = response.details;
		});
	};

	fetchMin = async () => {
		const response = await callApi("GET", "api/min");
		if (!response) return;
		runInAction(() => {
			this.min = response.details;
		});
	};

	deleteDataToDevice = async (id) => {
		const response = await callApi("DELETE", `api/datatodevice/${id}`);
		if (!response) return;
		runInAction(() => {
			this.items2 = this.items2.filter((item) => item.id !== id);
			this.loading = false;
		});
		toast.success("Device deleted");
	};

	fetchGateWayAccount = async () => {
		const response = await callApi("GET", "api/gatewayaccounts");
		if (!response) return;
		runInAction(() => {
			this.gateWayAccount = response.details;
		});
	};

	fetchScraperLogs = async () => {
		const response = await callApi("GET", "api/scraperlogs");
		if (!response) return;
		runInAction(() => {
			this.scraperlogs = response.details;
		});
	};

	fetchScraperLogId = async (id) => {
		const response = await callApi("GET", `api/scraperlog/${id}`);
		if (!response) return;
		runInAction(() => {
			this.scraperlog = response.details;
		});
	};


}