import React, { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../../index";
import { observer } from "mobx-react-lite";
import { Outlet,useParams } from "react-router-dom";
import { Spinner } from "../Common/Spinner";
import { ScraperTable } from "../Configuration/ScraperLogs/ScraperTable";
import moment from 'moment'
require ("moment-timezone");

const ErrorLogs = observer(() => {
    const rootStore = useContext(RootContext);
    const deviceStore = rootStore.deviceStore;


    const { id } = useParams();
    useEffect(() => {
        deviceStore.fetchScraperLogId(id);
    }, [id]);
    
    return (
        <div>
            {deviceStore.loading ? (
                <Spinner />
            ) : (
                <ScraperTable
                    data={deviceStore.scraperlog
                        .slice()
                     .sort((a, b) => new Date(a.observation_time) - new Date(b.observation_time))
                        .map((item, index) => {
                            return {
                              SN: index + 1,
                              observation_time: moment.utc(item.observation_time).tz('Asia/Kathmandu').format('YYYY-MM-DD HH:mm:ss'),
                              simnumber: item.simnumber,
                              condition:item.condition
                            }
                        })

                    }
                    headers={[
                        { key: "SN", label: "SN", width:'5%' },
                        { key: "simnumber", label: "Number", width:'25%' },
                        { key: "condition", label: "Status", width:'40%' },
                        { key: "observation_time", label: "Observation Time (Local)", width:'35%' }
                    ]}
      
                />)}


            <Outlet />
        </div>

    );
});


export default ErrorLogs
