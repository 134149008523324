import React, { useState, useEffect } from "react";
import Modal from "../../Common/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../../../index";
import { observer } from "mobx-react-lite";
import { useParams, useNavigate } from "react-router-dom";
import DragAndDrop from "../../DragAndDrop/DragAndDrop";
import Form, { FormBody, TextInput } from '../../Common/Form';
// import DragAndDrop from "../../DragAndDrop";

const MetaDataTemplateEdit = observer(() => {
  const rootStore = useContext(RootContext);
  const tagStore = rootStore.tagStore;

  const [caption, setCaption] = useState("");
  const [description, setDescription] = useState("");
  const [mdTemplateMd, setMdTemplateMd] = useState("")
  const [leftItems, setLeftItems] = useState([])
  const [rightItems, setRightItems] = useState([])

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const fetchData = await tagStore.fetchMetaDataTemplateById(id);
      const currentTemplate = tagStore.meta_data_template
      tagStore.fetchMetaDataTemplate();

      const fetchData3 = await tagStore.fetchMetaDataTemplateMetaDataById(id);
      const currentTemplateMetaData = tagStore.meta_data_template_meta_data

      setLeftItems(currentTemplateMetaData[0])
      setRightItems(currentTemplateMetaData[1])
      setCaption(currentTemplate[0]?.caption);
      setDescription(currentTemplate[0]?.description);
    })()
  }, []);

  return (
    <>
      <Modal
        title={"Edit MetaData Template"}
        closeModal={() => {
          navigate("/configuration/metadata/metadatatemplate");
        }}
      >
        <Form>
          <FormBody>
            <TextInput label={'Caption'} name="caption"  value={caption}  onChange={(e) => setCaption(e.target.value)} />
            <TextInput label={'Description'} name="description" value={description}  onChange={(e) => setDescription(e.target.value)} />
            <br />

          </FormBody>
        </Form>
        <DragAndDrop leftList={leftItems} rightList={rightItems} metaDataTempId={id} caption={caption} description={description} />
        {/* <button onClick={editplan}>Submit</button> */}
      </Modal>
    </>
  );
});

export default MetaDataTemplateEdit