import React, { useState, useEffect } from "react";
import Modal from "../Common/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../../index";
import { observer } from "mobx-react-lite";
import { useParams, useNavigate } from "react-router-dom";
import Form, { FormBody, TextInput, SelectInput } from '../Common/Form';

const EditUser = observer(() => {
  const rootStore = useContext(RootContext);
  const userStore = rootStore.userStore;

  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userStore.users.length) userStore.fetchUsers();
    const currentUser = userStore.users.find((user) => user.id === id);
    setUser(currentUser?.username);
    setPassword(currentUser?.password);
    setRole(currentUser?.user_role);
    setEmail(currentUser?.user_email)
  }, []);

  const editEmail = async (e) => {
    e.preventDefault();
    if (!user || !role) {
      toast.error("Missing fields");
      return;
    }

    const result = await userStore.editUser(id, { user: user, role: role, email: email });
    if (result) {
      userStore.fetchUsers();
      navigate("/configuration/roles")
      toast.success("User edited successfully");
    };
  };

  return (
    <>
      <Modal
        title={"Edit User details"}
        closeModal={() => {
          navigate("/configuration/roles");
        }}
      >

        <Form>
          <FormBody>
            <TextInput label={'User'} name="user" value={user} onChange={(e) => setUser(e.target.value)} />
            <TextInput label={'Email'} name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            {/* <TextInput label={'Role'} name="role" value={role} onChange={(e) => setRole(e.target.value)} /> */}
            <SelectInput
              label={'Role'}
              name={'role'}
              fields={{ label: 'name', value: 'name' }}
              options={userStore.roles}
              value={role}
              onChange={(e) => setRole(e.target.value)}
            />
            <br />
            <button onClick={editEmail}>Submit</button>
          </FormBody>
        </Form>
      </Modal>
    </>
  );
});

export default EditUser