import React, { useState } from "react";
import "../Common/DataTable/DataTable.css";
import { TablePagination } from "@mui/material";

export const SimDetailDataTable = ({
  headers,
  data,
  handleAdd,
  handleEdit,
  handleDelete,
  title,
  onRowClick,
  handleCheckboxChange,
  handleSendEmail
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10); 
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const toggleCheckbox = (id) => {
    const isSelected = selectedRows.includes(id);
    if (isSelected) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const isRowSelected = (id) => selectedRows.includes(id);

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedRows(selectAll ? [] : data.map((item) => item.id));
  };


  return (
    <div className="data-table-container">
      {title && <h1>{title}</h1>}
      <TablePagination
        rowsPerPageOptions={[10, 15, 25]}
        component="div"
        count={data ? data.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <table className="data-table">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                checked={selectAll}
                onChange={toggleSelectAll}
              />
            </th>
            {headers.map((title, index) => (
              <th key={index}>{title.label}</th>
            ))}
            <th>
              {handleAdd && (
                <span className="actions">
                  <i className="fa fa-plus" onClick={handleAdd}></i>
                </span>
              )}
            </th>
          </tr>
        </thead>

        <tbody>
          {data &&
            data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((data, index) => (
              <tr key={index} onClick={() => onRowClick && onRowClick(data)}>
                <td>
                  <input
                    type="checkbox"
                    checked={isRowSelected(data.id)}
                    onChange={() => {
                      toggleCheckbox(data.id);
                      handleCheckboxChange && handleCheckboxChange(data.id);
                    }}
                  />
                </td>
                {headers.map((h, i) => (
                  <td style={{ padding: "5px" }} key={i}>
                    {data[h.key]}
                  </td>
                ))}
                <td className="action-icons">
                  {handleEdit && (
                    <i
                      className="fa fa-pencil"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleEdit(data.id, data);
                      }}
                    />
                  )}
                  {handleDelete && (
                    <i
                      className="fa fa-trash"
                      style={{ cursor: "pointer", paddingLeft: "5px" }}
                      onClick={() => {
                        handleDelete(data.id);
                      }}
                    />
                  )}
                  {handleSendEmail && (
                    <i
                      className="fa fa-envelope"
                      style={{ cursor: "pointer", paddingLeft: "5px", color:"black" }}
                      onClick={handleSendEmail}
                    />
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
     
    </div>
  );
};
