import React from "react";
import { observer } from "mobx-react";
import { action } from "mobx";

class SelectInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openOptions: false,
      filterText: "",
      readyToClose: false,
      focusedElement: -1,
      scrollTop: 0
    };
  }

  componentDidMount() {
    this.setState({ focusedElement: -1 });
    window.addEventListener("mousedown", this.windowMouseDownListener);
    window.addEventListener("mouseup", this.windowMouseUpListener);

    let modalElement = document.getElementsByClassName("modalChildren");
    if (!!modalElement && !!modalElement.length) {
      modalElement[modalElement.length - 1].addEventListener("scroll", this.scrollListener);
      this.setState({ scrollTop: modalElement[modalElement.length - 1].scrollTop });
    }
  }

  componentWillUnmount() {
    this.setState({ focusedElement: -1 });

    window.removeEventListener("mousedown", this.windowMouseDownListener);
    window.removeEventListener("mouseup", this.windowMouseUpListener);

    let modalElement = document.getElementsByClassName("modalChildren")[0];
    if (!!modalElement && !!modalElement.length) modalElement[modalElement.length - 1].removeEventListener("scroll", this.scrollListener);
  }

  windowMouseDownListener = (e) => {
    let { id, label } = this.props;
    if (!document.getElementById(id || label).contains(e.target)) {
      this.setState({ readyToClose: true });
    }
  };

  windowMouseUpListener = () => {
    if (this.state.readyToClose) {
      this.setState({ openOptions: false, readyToClose: false });
    }
  };

  scrollListener = (e) => {
    this.scrollTop = e.target.scrollTop;
    this.setState({ scroolTop: e.target.value });
  };

  onChangeFilterText = (e) => {
    this.setState({ filterText: e.target.value, focusedElement: -1 });
  };

  onInputKeyDown = (e) => {
    const { id, label } = this.props;
    let options = document.querySelectorAll(`.${(id || label).replace(/\s/g, "")}-option`);
    if (+e.keyCode === 40 && this.state.focusedElement + 1 < options.length) {
      e.preventDefault();
      options[this.state.focusedElement + 1].focus();
      this.setState({ focusedElement: this.state.focusedElement + 1 });
    } else if (+e.keyCode === 38 && this.state.focusedElement - 1 > -1) {
      e.preventDefault();
      options[this.state.focusedElement - 1].focus();
      this.setState({ focusedElement: this.state.focusedElement - 1 });
    } else if (+e.keyCode === 13 && this.state.focusedElement >= -1 && this.state.focusedElement < options.length) {
      this.onOptionClicked(options[this.state.focusedElement].getAttribute("value"));
    }
  };

  toggleOptionOpen = action(() => {
    this.setState({ openOptions: !this.state.openOptions, filterText: "" });
  });

  getFilteredOptions = (options) => {
    if (this.state.filterText === "") return options;
    const { filterOptions, fields } = this.props;
    if (!filterOptions) return options.filter((item) => item[fields.label].toString().toLowerCase().indexOf(this.state.filterText.toString().toLowerCase()) === 0);
    return filterOptions(options, this.state.filterText.toString().toLowerCase());
  };

  onOptionClicked = (value) => {
    const { onChange } = this.props;
    onChange({ target: { value } });
    this.toggleOptionOpen();
  };

  _renderOptions = (options, onCreateItem) => {
    const { id, label, value, isLoading, fields, defaultValue } = this.props;
    return (
      <div
        className="multi-select-options"
        style={{ transform: `translateY(${4 - this.state.scrollTop}px)` }}
      >
        <div className="options-controller">
          <input
            type="text"
            onFocus={() => this.setState({ focusedElement: -1 })}
            placeholder={`Search ${id || label}`}
            onChange={this.onChangeFilterText}
            onKeyDown={this.onInputKeyDown}
            style={{ minWidth: "250px" }}
            autoFocus
          />
        </div>
        {isLoading && !options.length ? (
          <p>loading</p>
        ) : (
          <ul id={`${id || label}-select-options`}>
            {!defaultValue && (
              <li
                tabIndex={-1}
                onKeyDown={this.onInputKeyDown}
                className={`${(id || label).replace(/\s/g, "")}-option`}
                key={`${id || label}-option-null`}
                value={""}
                onClick={(e) => this.onOptionClicked("")}
                style={{ background: value === "" ? "#efefef" : "" }}
              >
                --
              </li>
            )}
            {options.map((item, i) => {
              return (
                <li
                  tabIndex={i}
                  onKeyDown={this.onInputKeyDown}
                  className={`${(id || label).replace(/\s/g, "")}-option`}
                  key={`${id || label}-option-${item[fields.value]}`}
                  value={item[fields.value]}
                  onClick={(e) => this.onOptionClicked(item[fields.value])}
                  style={{ background: value === item[fields.value] ? "#efefef" : "" }}
                  data-toggle="tooltip"
                  title={item[fields.value]}
                >
                  {item[fields.label]}
                </li>
              );
            })}
          </ul>
        )}
        {onCreateItem && (
          <div
            className="multi-select-item-create"
            onClick={() => {
              onCreateItem();
              this.toggleOptionOpen();
            }}
          >
            Create New Item
          </div>
        )}
      </div>
    );
  };

  render() {
    const { id, displayLabel, label, options, fields, sortOptions, small, onCreateItem } = this.props;
    let value = !!this.props.value || +this.props.value === 0 ? this.props.value : this.props.defaultValue;
    let selectedOption = Array.isArray(options) && (!!value || +value === 0) && options.find((item) => item[fields.value] === value);

    return (
      <div className="formInput flex flex-row">
        {label !== "" && <label className="flex flex-column justify-center">{displayLabel || label}</label>}
        <div
          id={id || label.replace("*", "")}
          className="formInputMulti"
        >
          <div
            className={`${small ? "multi-select-input-small" : "multi-select-input"}`}
            onClick={this.toggleOptionOpen}
          >
            <div>{(!!value || +value === 0) && selectedOption ? selectedOption[fields.label] : "--"}</div>
            <i className="fa fa-caret-down" />
          </div>
          {this.state.openOptions &&
            this._renderOptions(
              this.getFilteredOptions(options).slice().sort(
                sortOptions || ((a, b) => (!!a[fields.label] && b[fields.label] && a[fields.label].toString().toLowerCase().trim() > b[fields.label].toString().toLowerCase().trim() ? 1 : -1))
              ),
              onCreateItem
            )}
        </div>
      </div>
    );
  }
}

export default observer(SelectInput);
