import { makeObservable, observable, action, computed } from 'mobx';
import { setLocalStorage, getLocalStorage } from '../libs/localStorage';

export class uiStore {
  isNavOpen = getLocalStorage('isNavOpen') !== false;
  windowWidth = window.innerWidth;

  constructor() {
    makeObservable(this, {
      isNavOpen: observable,
      windowWidth: observable,
      mobileView: computed,
      navWidth: computed,
      toggleNav: action,
    });

    window.addEventListener('resize', () => (this.windowWidth = window.innerWidth));
    if (this.mobileView && this.isNavOpen) this.toggleNav();
  }

  get mobileView() {
    return this.windowWidth < 900;
  }

  get navWidth() {
    return this.isNavOpen ? '300px' : '0px';
  }

  toggleNav() {
    this.isNavOpen = !this.isNavOpen;
    setLocalStorage('isNavOpen', this.isNavOpen);
  }
}
