import React, { useState, useEffect,useContext } from "react";
import "./Table.css";
import moment from "moment"
import Modal2 from "./cborModal"
import { RootContext } from "../../index";
import { Spinner } from "../Common/Spinner";
import { getDisplayDate } from "../../libs/date";


export const DataTable = ({ headers, data, refTime,retrieveData }) => {



	const rootStore = useContext(RootContext);
	const rawDataStore = rootStore.rawDataStore;

	const [showModal, setShowModal] = useState(false)
	const [convertedValue, setConvertedValue] = useState("");
	const [parsedValue, setParsedValue] = useState("")
	const [startTime, setStartTime] = useState("")
	const [loading, setLoading] = useState(false)
	const [postData,setPostData] = useState({
		message_id:"",
		mobileownerid:"",
		mobileid:"",
		receive_utc:"",
		userData:""
	})

	const setNewForm = async (e, userData, dataId,message_id,mobileownerid,mobileid,receive_utc) => {
		setShowModal(!showModal)
		setConvertedValue(userData);
		setPostData({message_id,mobileownerid,mobileid,receive_utc,userData,id:dataId})

		const data2 = await rawDataStore.fetchConvertedData(userData)
		const convertedData = rawDataStore.convertedData

		const returnData = refTime.filter((time) => time.rawdata_id == dataId)
		let referenceTime = returnData.length ? returnData[0].start_time : ""
		let dataTime = getDisplayDate(moment.utc(referenceTime))

		let parsedData = Array.isArray(convertedData.data)
			? JSON.stringify(convertedData.data, null, 2)
			: convertedData.data;


		setStartTime(dataTime)
		setParsedValue(parsedData);
		setLoading(false)
	};

	const handleClick = (e, userData, dataId,message_id,mobileownerid,mobileid,receive_utc) => {
		setLoading(true);
		setNewForm(e, userData, dataId,message_id,mobileownerid,mobileid,receive_utc);
	};

	data = data.map((data, index) => {
		let Id = data.id
		let SN = index + 1
		let Mobileid = data.mobileid
		let Received = getDisplayDate(moment.utc(data.receive_utc))
		let Size = data.otamessagesize
		let Region = data.region_name
		let SIN = data.sin
		let MobileOwnerId = data.mobileownerid
		let ObservationTime = getDisplayDate(moment.utc(data.observation_date))

		let UserData = data.userdata.data.map(decimal => {
			const hexValue = decimal.toString(16);
			return hexValue.length === 1 ? '0' + hexValue : hexValue;
		}).join('');

		const isAscii = /[a-zA-Z]/.test(UserData);
		if (!isAscii) {
			function hexToAscii(hexString) {
				let asciiString = '';
				for (let i = 0; i < hexString.length; i += 2) {
					asciiString += String.fromCharCode(parseInt(hexString.substr(i, 2), 16));
				}
				return asciiString;
			}

			UserData = ("9F" + hexToAscii(UserData)).toLowerCase()
		}
		return { Id,SN, Mobileid, Received, Size, SIN, Region, MobileOwnerId, UserData, ObservationTime }
	})


	return (
		<div className="data-table-container-rawdata">
			{/* {title && <h1>{title}</h1>} */}
			<table className="data-table-rawdata">
				<thead>
					<tr style={{textAlign:"center"}}>
						{headers.map((title, index) => (
							<th key={index}>{title}</th>
						))}
					</tr>
				</thead>

				<tbody>
					{data &&
						data.map((data, index) => (
							<tr key={index} onClick={(e) => handleClick(e, data.UserData, data.Id,data.message_id,data.MobileOwnerId
								,data.Mobileid,data.Received)}>
								{headers.map((h, i) => (
									<td
									style={{ padding: "1px", textAlign:(h === 'UserData' ? "left" : "center"),whiteSpace: (h === 'Mobileid' || h === 'Received') ? 'nowrap' : 'normal' }}
										key={i}
									>
										{data[h]}
									</td>
								))}
							</tr>
						))}
				</tbody>
			</table>

			{showModal && (
				<Modal2
					title={"Cbor To Converted Value"}
					closeModal={() => {
						setShowModal(false);
					}}
					referencetime={startTime}
					postData={postData}
					retrieveData = {retrieveData}
				>

					{loading ? (
						<Spinner />
					) : (
						<div className="verticalform">
							<textarea
								type="text"
								name="rawdata"
								value={convertedValue}
								onChange={setNewForm}
							/>

							<textarea
								type="text"
								name="rawdata2"
								value={parsedValue}
								onChange={setNewForm}
							/>
						</div>
					)}

				</Modal2>
			)}
		</div>
	);
};
