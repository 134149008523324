import { preferences } from "./localStorage";
import moment from 'moment'

export const getDisplayDate = (date, options = {returnMills: false, format: 'YYYY-MM-DD HH:mm:ss'}) => {
  let {returnMills, format} = options,
    timeZoneName = preferences.get().timezone || 'UTC',
    utcOffset = moment.tz(timeZoneName)._offset,
    sample_time =  moment.utc(date).valueOf(),
    sample_time_zone = moment.utc(date)._tzm * 60000 || 0,
    local_time_zone_offset = utcOffset * 60000,

    display_time_mills = sample_time - sample_time_zone + local_time_zone_offset;

  if(returnMills) return display_time_mills;
  return moment.utc(sample_time - sample_time_zone + local_time_zone_offset).format(format)
}; 