import React, { useState,useContext } from "react";
import "../Common/DataTable/DataTable.css";
import { TablePagination} from "@mui/material";
import {useNavigate } from "react-router-dom";
import { RootContext } from "../../index";


export const DataTable4 = ({
  headers,
  data,
  handleAdd,
  handleEdit,
  handleDelete,
  title,
  onRowClick,
  handleCheckboxChange,
}) => {
  const rootStore = useContext(RootContext);
  const uiStore = rootStore.uiStore;
  
  const navigate = useNavigate()
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(100); 
  const [page, setPage] = useState(0);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const toggleCheckbox = (id) => {
    const isSelected = selectedRows.includes(id);
    if (isSelected) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const isRowSelected = (id) => selectedRows.includes(id);

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedRows(selectAll ? [] : data.map((item) => item.id));
  };

  const pageRedirect = (e) => {
    const url = `${window.location.origin}/#/singledevicedetail/sim/metadata/${e}`;
    window.open(url, "_blank"); 
    }

    const requestSort = key => {
      let direction = 'asc';
      if (sortConfig.key === key && sortConfig.direction === 'asc') {
        direction = 'desc';
      }
      setSortConfig({ key, direction });
    };

    const sortedData = React.useMemo(() => {
      let sortableData = [...data];

      if (sortConfig !== null && sortConfig.key == "Balance" ) {
        sortableData.sort((a, b) => {
          let aValue = parseFloat(a['remaining_balance']);
          let bValue = parseFloat(b['remaining_balance']);
  
          if (aValue < bValue) {
            return sortConfig.direction === 'asc' ? -1 : 1;
          }
          if (aValue > bValue) {
            return sortConfig.direction === 'asc' ? 1 : -1;
          }
          return 0;
        });
      } else if (sortConfig !== null && sortConfig.key == "Volume") {
        sortableData.sort((a, b) => {
          let aValue = a['remaining_volume'];
          let bValue = b['remaining_volume'];
  
          if (aValue < bValue) {
            return sortConfig.direction === 'asc' ? -1 : 1;
          }
          if (aValue > bValue) {
            return sortConfig.direction === 'asc' ? 1 : -1;
          }
          return 0;
        });
      } 
  
      return sortableData.map((data, index) => {
        let SN = index + 1;
        let phonenumber = data.phonenumber;
        let remaining_balance = data.remaining_balance;
        let remaining_volume = data.remaining_volume;
        let station_name = data.station_name
  
        return { SN, phonenumber, remaining_balance, remaining_volume,station_name };
      });
    }, [data, sortConfig]);

  return (
    <div className="data-table-container" style={uiStore.isNavOpen ? undefined : {marginLeft:"30px"}}>
      <div style={{display:'flex', justifyContent:"space-between"}}>
      {title && <h1>{title}</h1>}
      <TablePagination
        rowsPerPageOptions={[5, 15, 25,50,100]}
        component="div"
        count={data ? data.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      
      <table className="data-table">
        <thead>
          <tr>
            {headers.map((title, index) => (
              <th key={index}  onClick={() => requestSort(title.label)}  style={{ width: title.width }}>{title.label}{(title.label === 'Balance' || title.label === 'Volume') && <i class="fa fa-sort" aria-hidden="true"></i>}</th>
            ))}
            <th>
              {handleAdd && (
                <span className="actions">
                  <i className="fa fa-plus" onClick={handleAdd}></i>
                </span>
              )}
            </th>
          </tr>
        </thead>

        <tbody>
          {sortedData &&
            sortedData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((data, index) => (
  
              <tr style={{cursor:"pointer"}} key={index} onClick={() => pageRedirect(data && data.phonenumber ? data.phonenumber :data.device_id)}>
        
                {headers.map((h, i) => (
                  <td style={{ padding: "5px" }} key={i}>
                    {data[h.key]}
                  </td>
                ))}
                 <td className="action-icons">
                  {handleEdit && (
                    <i
                      className="fa fa-pencil"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleEdit(data.id, data);
                      }}
                    />
                  )}
                  {handleDelete && (
                    <i
                      className="fa fa-trash"
                      style={{ cursor: "pointer", paddingLeft: "5px" }}
                      onClick={() => {
                        handleDelete(data.id);
                      }}
                    />
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
     
    </div>
  );
};
