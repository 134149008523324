import "./App.css";
import { ToastContainer } from "react-toastify";
import Sidebar from "./components/Sidebar/SideBar.js";

function App() {
	return (
		<div className="App">
			<Sidebar/>
			<ToastContainer
				position="bottom-right"
				hideProgressBar={true}
				autoClose={1000}
				theme="dark"
			/>	
		</div>
	);
}

export default App;
