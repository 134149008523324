import React, { useState, useEffect } from "react";
import Modal from "../Common/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../..";
import { observer } from "mobx-react-lite";
import { useParams, useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export const EditDevice2Server = observer(() => {
  const rootStore = useContext(RootContext);
  const serverStore = rootStore.serverStore;

  const [deviceId, setDeviceId] = useState("");
  const [serverId, setServerId] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();

  

  useEffect(() => {
    if (!serverStore.device2server.length) serverStore.fetchDevice2Server();
    const currentserver = serverStore.device2server.find((server) => server.id === id);
    setDeviceId(currentserver?.device_id);
    setServerId(currentserver?.server_id);
  }, []);

  const [redirectServerInfo, setReDirectServerInfo] = useState({
    device_id: "",
    server_id: ""
});

const setNewServerInfo = (e) => {
    const { name, value } = e.target;
    setReDirectServerInfo((prev) => ({ ...prev, [name]: value  }));
};


  const editServer = async (e) => {
    e.preventDefault();
    if (!deviceId || !serverId) {
      toast.error("Missing fields");
      return;
    }
    const result = serverStore.editDevice2Server(id, { server_id: serverId, device_id: deviceId });
    if (result) navigate("/servers");
  };

  return (
    <>
      <Modal
        title={"Edit server details"}
        closeModal={() => {
          navigate("/servers");
        }}
      >
        <form
          className="Form"
          autoComplete="off"
        >
          <label>DeviceId</label>
          <input
            type="text"
            name="device_id"
            value={deviceId}
            autoComplete="off"
            onChange={(e) => setDeviceId(e.target.value)}
          />

          <label>Server Url</label>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-label">Server</InputLabel>
            <Select
              name="server_id"
              label="server_id"
              value={serverId}
              onChange={(e) => setServerId(e.target.value)}
            >
              {serverStore.servers.map((server) => (
                <MenuItem value={server.id}>{server.domain_name}</MenuItem>
              ))}
            </Select>
          </FormControl>


          <br />
          <button onClick={editServer}>Submit</button>
        </form>
      </Modal>
    </>
  );
});
