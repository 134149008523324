import { commonStore } from "./common_store";
import { dataUsageStore } from "./dataUsage_store";
import { rawDataStore } from "./rawData_store";
import { serverStore } from "./server_store";
import { deviceStore } from "./device_store";
import { uiStore } from "./ui_store";
import { serviceProviderStore } from "./serviceProvider_store";
import { planStore } from "./plan_store";
import { protocolStore } from "./protocol_store";
import { simDetailStore } from "./simDetail_store";
import { userStore } from "./user_store";
import { rechargeStore } from "./recharge_store";
import { tagStore } from "./tag_store";

export class RootStore {
	constructor() {
		this.commonStore = new commonStore();
		this.dataUsageStore = new dataUsageStore();
		this.rawDataStore = new rawDataStore()
		this.serverStore = new serverStore()
		this.deviceStore = new deviceStore()
		this.uiStore = new uiStore()
		this.serviceProviderStore = new serviceProviderStore()
		this.planStore = new planStore()
		this.protocolStore = new protocolStore()
		this.simDetailStore = new simDetailStore()
		this.userStore = new userStore()
		this.rechargeStore = new rechargeStore()
		this.tagStore = new tagStore()
	}
}
