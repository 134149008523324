import React, { useContext, useEffect, useState } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { RootContext } from "../../../index";
import { DataTable2 } from '../../RawData/Table2';
import Form, { FormBody, FormActions, TextInput, SelectInput } from '../../Common/Form';
import { BalanceTable } from './BalanceTable';
import FormMultiSelectInput from '../../Common/FormSelectMultiInput';

const CheckBalance = observer(() => {
  const rootStore = useContext(RootContext);
  const dataUsageStore = rootStore.dataUsageStore;
  const tagStore = rootStore.tagStore;

  const [infoBalVol, setInfoBalVol] = useState({
    "balance":"",
    "volume": "",
    "number":"",
    "tags":[]
  });

const [tableData,setTableData] = useState([])
const onParameterOptionClicked = item => item.value = !item.value;
const [isLoading, setIsLoading] = useState(true);
const [tagData, setTagData] = useState([]);

useEffect(() => {
  const fetchData = async () => {
    await dataUsageStore.fetchInfoBalVol();
     await tagStore.fetchTags();
    const data = tagStore.tags;
   setTagData(data);
    setIsLoading(false);
  };
  fetchData();
}, [dataUsageStore, tagStore]);




  const setNewForm = (e) => {
    const { name, value } = e.target;
    setInfoBalVol((prev) => ({ ...prev, [name]: value }));
  };

  const setNewForm2 = (name, e) => {
    setInfoBalVol((prev) => ({ ...prev, [name]: e }));
};

const retrivedata = async (e) => {
  e.preventDefault();
  
    e.preventDefault();
    const filteredData = dataUsageStore.infoBalVol.filter((item) => {
        const balanceCondition = infoBalVol.balance !== "" ? parseFloat(item.remaining_balance) < parseFloat(infoBalVol.balance) : true;
        const volumeCondition = infoBalVol.volume !== "" ? parseFloat(item.remaining_volume) < parseFloat(infoBalVol.volume) : true;
        const number = infoBalVol.number !== "" ? (item.phonenumber == infoBalVol.number) : true;
        const tags = infoBalVol.tags.length > 0 ? infoBalVol.tags.includes(item.tag_id) : true;
        return balanceCondition && volumeCondition && number && tags
    });

    setTableData(filteredData);  
}




  return (   
    <div className='container'>
        <div className='query-container'>
          <Form>
            <FormBody>
              <TextInput label={'Balance'} name={'balance'} value={infoBalVol.balance} onChange={setNewForm} />
              <TextInput label={'Volume'} name={'volume'} value={infoBalVol.volume} onChange={setNewForm} />
              <TextInput label={'Number'} name={'number'} value={infoBalVol.number} onChange={setNewForm} />
              <FormMultiSelectInput
              id='tags-multi-select'
              label={'Tags'}
              options={tagData && tagData.length > 0 && tagData.map(tag => ({ id: tag.id, label: tag.caption }))}
              value={infoBalVol.tags}
              onChange={(value) => setNewForm2("tags", value)}
              optionClicked={onParameterOptionClicked}
              isLoading={isLoading}
            />
            </FormBody>
           
            <br />
            <button style={{ maxWidth: "20%" }} onClick={retrivedata}>Retrieve Data</button>

          </Form>
        </div>

        <BalanceTable
          title="Decoded Data"
          data={tableData}
          headers={["SN", "Number","Balance", "Volume"]}
        />
      
      </div>
  )
})

export default CheckBalance