import React, { useState, useEffect } from "react";
import Modal from "../../Common/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../../..";
import { observer } from "mobx-react-lite";
import { DataTable } from "../../Common/DataTable/DataTable";
import { Outlet, useNavigate } from "react-router-dom";
import { Spinner } from "../../Common/Spinner";
import { DataTable3 } from "../../Server/DataTable3";
import Form, { FormBody, TextInput } from '../../Common/Form';

const Folder = observer(() => {
    const rootStore = useContext(RootContext);
    const deviceStore = rootStore.deviceStore;

    const [showAddFolder, setShowAddFolder] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);


    const navigate = useNavigate();

    useEffect(() => {
        if (!deviceStore.folders.length) deviceStore.fetchfolders();
    }, []);

    const [folderInfo, setFolderInfo] = useState({
        name: "",
        description: ""
    });

    const setNewForm = (e) => {
        const { name, value } = e.target;
        setFolderInfo((prev) => ({ ...prev, [name]: value }));
    };


    const addFolder = async (e) => {
        e.preventDefault();
        if (!folderInfo.name || !folderInfo.description) {
            toast.error("Missing fields");
            return;
        }
        const addedfolderInfo = await deviceStore.addFolder(folderInfo);
         await deviceStore.fetchfolders();
        setFolderInfo({ name: "", description: "" });
       

        setShowAddFolder(false)
    };

    const deleteSelectedFolders = async (e) => {
        const confirm = window.confirm("Delete selected folder?");
        if (selectedRows.length != 0 && confirm) {
            await Promise.all(selectedRows.map((id) => deviceStore.deleteFolder(id)));
            setSelectedRows([]);
        } else if (confirm) {
            await deviceStore.deleteFolder(e);
        }
    };

    const toggleCheckbox = (id) => {
        const isSelected = selectedRows.includes(id);

        if (isSelected) {
            setSelectedRows((prevRows) => prevRows.filter((rowId) => rowId !== id));
        } else {
            setSelectedRows((prevRows) => [...prevRows, id]);
        }
    };



    const handleCheckboxChange = (id) => {
        const isSelected = selectedRows.includes(id);

        if (isSelected) {
            setSelectedRows((prevRows) => prevRows.filter((rowId) => rowId !== id));
        } else {
            setSelectedRows((prevRows) => [...prevRows, id]);
        }

        handleCheckboxChange && handleCheckboxChange(selectedRows);
    };

    return (
        <div>
            {deviceStore.loading ? (
                <Spinner />
            ) : (
                <DataTable3
                    title="Folders List"
                    data={deviceStore.folders
                        .map((item, index) => {
                            return {
                                ...item,
                                SN: index + 1,
                            }
                        })

                    }
                    headers={[
                        { key: "SN", label: "SN" },
                        { key: "name", label: "Name" },
                        { key: "description", label: "Description" },
                        // { key: "volume", label: "Volume(kb)" }
                    ]}
                    handleAdd={() => setShowAddFolder(true)}
                    // handleEdit={(id) => {
                    //     navigate(`/configuration/plan/${id}`);
                    // }}
                    handleDelete={(e) => deleteSelectedFolders(e)}
                    handleCheckboxChange={(id) => toggleCheckbox(id, selectedRows, setSelectedRows)}
                />)}

            {showAddFolder && (
                <Modal
                    title={"Add Folders"}
                    closeModal={() => {
                        setShowAddFolder(false);
                        setFolderInfo({ caption: "", description: "", volume: "" });
                    }}
                >

                    <Form>
                        <FormBody>
                            <TextInput label={'Name'} name="name" value={folderInfo.name} onChange={setNewForm} />
                            <TextInput label={'Description'} name="description" value={folderInfo.description} onChange={setNewForm} />
                            <button onClick={(e) => addFolder(e)}>Submit</button>
                        </FormBody>
                    </Form>
                </Modal>
            )}
            <Outlet />
        </div>

    );
});


export default Folder
