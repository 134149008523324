import { useContext, useEffect, useState } from 'react'
import { useClickAway } from 'react-use'
import "../Sidebar/SideBar.css"
import { IconButton } from '../Common/Form'
import { observer } from "mobx-react-lite";
import { RootContext } from "../..";
import NavBar from "../NavBar"
import { Outlet, useNavigate } from "react-router-dom";
import "../../App.css";


const Sidebar = observer(() => {
  const rootStore = useContext(RootContext);
  const deviceStore = rootStore.deviceStore;
  const uiStore = rootStore.uiStore
  const dataUsageStore = rootStore.dataUsageStore
  const data = deviceStore.deviceTags
  const folders = dataUsageStore.folders

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState(data);
  const [folderData, setFolderData] = useState(folders);
  const [collapsedFolders, setCollapsedFolders] = useState({});
  const deviceData = deviceStore.device

  const navigate = useNavigate()

  useEffect(() => {
    deviceStore.fetchdeviceTags()
    dataUsageStore.fetchFolders();
  }, [deviceData]);

  useEffect(() => {
    setFolderData(folders)
    setFilteredData(data);
  }, [data]);


  const toggleNav = () => {
    uiStore.toggleNav();

  };

  const handleSearch = (e) => {
    const term = (e.target.value).trim();
    setSearchTerm(term);
    const filtered = data.filter((item) => {
      const folder = dataUsageStore.folders.find(folder => folder.id == item.folder_id);
      const folderName = folder ? folder.name.toLowerCase() : item.folder_id.toString();
      const tagName = item.tags?.map((tag)=> tag.caption)
      const deviceId = item.device_id.toString();
      return deviceId.includes(term) || folderName.includes(term) || tagName && tagName.some((data) => data.startsWith(term))
    });
    setFilteredData(filtered);
  };

  const clearSearch = () => {
    setSearchTerm('');
    setFilteredData(data);
  };

  const toggleFolder = (folderId) => {
    setCollapsedFolders(prevState => ({
      ...prevState,
      [folderId]: !prevState[folderId]
    }));
  };


  const groupedData = {};
  filteredData.forEach(item => {
    const { device_id, folder_id } = item;
    const folder = dataUsageStore.folders.find(folder => folder.id == folder_id);
    const folderName = folder ? folder.name : folder_id;
    if (!groupedData[folderName]) {
      groupedData[folderName] = [];
    }
    groupedData[folderName].push({ device_id });
  });


  return (
    <div x className='mainContainer' style={{overflowX:"hidden"}}>
      <div className='container-1' style={uiStore.isNavOpen ? { width: '100vw' } : { width: '0vw' }}>
        <>
          <div className='Sidebar' style={uiStore.mobileview ? { marginLeft: uiStore.isNavOpen ? '0' : '-100%', width: '80vw' } : { width: uiStore.navWidth }} >
            <div className="SidebarHeader">
              <span>OrbComm</span>
              <div>
              {uiStore.mobileView && <IconButton icon="fa fa-arrow-left" onClick={() => uiStore.toggleNav()} color='#367fa9' primary />}
                </div>
            </div>
            <div className="Search inline-it">
              <i className='fa fa-search' />
              <input placeholder="Search" value={searchTerm} onChange={handleSearch} />
              {<i className='fa fa-close' style={{ right: 15, color: '#bdbdbd' }} onClick={clearSearch} />}
            </div>
            <ul style={{ cursor: 'pointer' }}>
              {Object.keys(groupedData).map(folderId => (
                <div key={folderId}>
                  <div onClick={() => toggleFolder(folderId)} style={{ display: 'flex', alignItems: 'center', marginTop:'5px' }}>
                    <i className={`fa fa-folder open}`} style={{  marginLeft:'40px'}} />
                    <span style={{ color: "white", fontSize: "1em" }}>{folderId}</span>
                  </div>
                  {!collapsedFolders[folderId] && groupedData[folderId].map((item, idx) => (
                    <div key={idx} style={{ marginLeft:'50px', paddingLeft: "1.2em", fontSize: "1em"}}>
                      <a onClick={() => {
                        const url = item.device_id.toString().length == 10 ? `/singledevicedetail/sim/metadata/${item.device_id}` : `/singledevicedetail/orbcomm/metadata/${item.device_id}`

                        navigate(url)
                      }}>
                        <span>{item.device_id}</span>

                      </a>
                    </div>
                  ))}
                </div>
              ))}
            </ul>
          </div>
        </>
      </div>

      <div className='container-2' style={uiStore.mobileView ?  {marginLeft: '0'} : uiStore.isNavOpen ? { marginLeft: '15vw', width: '85vw', transition: 'margin-left 0.5s, width 0.5s' } : { width: '100vw', transition: 'margin-left 0.5s, width 0.5s' }}>
        <NavBar />
        <Outlet />
      </div>

    </div>

  )
})

export default Sidebar