import React, { useState, useEffect } from "react";
import Modal from "../Common/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../../index";
import { observer } from "mobx-react-lite";
import { Outlet, useNavigate } from "react-router-dom";
import { Spinner } from "../Common/Spinner";
import { DataTable3 } from "../Server/DataTable3";
import { RechargeTable } from "./RechargeTable";

const RechargeList = observer(() => {
    const rootStore = useContext(RootContext);
    const rechargeStore = rootStore.rechargeStore;

    const [showAddPlan, setShowAddPlan] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);


    const navigate = useNavigate();

    useEffect(() => {
        rechargeStore.fetchRechargeList();
    }, []);

    const [planInfo, setPlanInfo] = useState({
        caption: "",
        description: "",
        volume:""
    });

    const setNewForm = (e) => {
        const { name, value } = e.target;
        setPlanInfo((prev) => ({ ...prev, [name]: value }));
    };


    // const addPlan = async (e) => {
    //     e.preventDefault();
    //     if (!planInfo.caption || !planInfo.description || !planInfo.volume) {
    //         toast.error("Missing fields");
    //         return;
    //     }
    //     const addedPlanInfo = await planStore.addPlan(planInfo);

    //     if (!addedPlanInfo.id) {
    //         await planStore.fetchPlan();

    //         setPlanInfo({ caption: "", description: "" });
    //     }

    //     setShowAddPlan(false)
    // };


    const deleteSelectedNumbers = async () => {
        const confirm = window.confirm("Delete selected numbers ?");
        if (confirm) {
          await Promise.all(selectedRows.map((id) => rechargeStore.deleteRecharge(id)));
          setSelectedRows([]); 

          //Reload the table
          const fetchDatas = await rechargeStore.fetchRechargeList()

        }
      };

      const toggleCheckbox = (id) => {
        const isSelected = selectedRows.includes(id);
        
        if (isSelected) {
          setSelectedRows((prevRows) => prevRows.filter((rowId) => rowId !== id));
        } else {
          setSelectedRows((prevRows) => [...prevRows, id]);
        }
      };
      


      const handleCheckboxChange = (id) => {
        const isSelected = selectedRows.includes(id);
      
        if (isSelected) {
          setSelectedRows((prevRows) => prevRows.filter((rowId) => rowId !== id));
        } else {
          setSelectedRows((prevRows) => [...prevRows, id]);
        }
      
        handleCheckboxChange && handleCheckboxChange(selectedRows);
      };

      
    return (
        <div>
            {rechargeStore.loading ? (
                <Spinner />
            ) : (
                <RechargeTable
                    title="Recharge List"
                    data={rechargeStore.recharge
                           .map((item, index) => {
                            const rechargeAlert = {
                              color: item.isdeleted == true ? 'blue' : 'red',
                              borderRadius: "20px",
              
                            };
                            return {
                                ...item,
                                SN: index + 1,
                                isdeleted: <tr style={rechargeAlert}>{item.isdeleted == true ? "Already Recharged" : "In Recharge Queue"}</tr>,
                            }
                        })
                    

                    }
                    headers={[
                        { key: "SN", label: "SN" },
                        { key: "phonenumber", label: "Number" },
                        { key: "provider", label: "Provider" },
                        { key: "remaining_balance", label: "Remaining Balance" },
                        { key: "remaining_volume", label: "Remaining Volume" },
                        { key: "isdeleted", label: "Processed ?" }
                    ]}
                    // handleAdd={() => setShowAddPlan(true)}
                    // handleEdit={(id) => {
                    //     navigate(`/configuration/plan/${id}`);
                    // }}
                     handleDelete={deleteSelectedNumbers}
                    handleCheckboxChange={(id) => toggleCheckbox(id, selectedRows, setSelectedRows)}
                />)}

            {/* {showAddPlan && (
                <Modal
                    title={"Add Plan"}
                    closeModal={() => {
                        setShowAddPlan(false);
                        setPlanInfo({ caption: "", description: "", volume:"" });
                    }}
                >
                    <form className="Form">
                        <label>Caption</label>
                        <input
                            type="text"
                            name="caption"
                            value={planInfo.caption}
                            onChange={setNewForm}
                        />

                        <label>Description</label>
                        <input
                            type="text"
                            name="description"
                            value={planInfo.description}
                            onChange={setNewForm}
                        />

                        <label>Volume(kB)</label>
                        <input
                            type="text"
                            name="volume"
                            value={planInfo.volume}
                            onChange={setNewForm}
                        />
                        <br />
                        <button onClick={(e) => addPlan(e)}>Submit</button>
                    </form>
                </Modal>
            )} */}
            <Outlet />
        </div>

    );
});


export default RechargeList
