import React, { useState, useEffect } from "react";
import Modal from "../../Common/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../../../index";
import { observer } from "mobx-react-lite";
import { useParams, useNavigate } from "react-router-dom";
import Form, { FormBody, TextInput } from '../../Common/Form';

const EditProtocol = observer(() => {
  const rootStore = useContext(RootContext);
  const protocolStore = rootStore.protocolStore;

  const [caption, setCaption] = useState("");
  const [description, setDescription] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!protocolStore.protocol.length) protocolStore.fetchplan();
    const currrentProtocol = protocolStore.protocol.find((protocol) => protocol.id === id);
    setCaption(currrentProtocol?.caption);
    setDescription(currrentProtocol?.description);
  }, []);

  const editProtocol = async (e) => {
    e.preventDefault();
    if (!caption || !description) {
      toast.error("Missing fields");
      return;
    }
    const result = await protocolStore.editProtocol(id, { caption: caption, description: description });
    if (result) {
      navigate("/configuration/protocols")
      toast.success("Plan edited successfully");
    };
  };

  return (
    <>
      <Modal
        title={"Edit Protocol Details"}
        closeModal={() => {
          navigate("/configuration/protocols");
        }}
      >

        <Form>
          <FormBody>
            <TextInput label={'Caption'} name="caption" value={caption}  onChange={(e) => setCaption(e.target.value)} />
            <TextInput label={'Description'} name="description" value={description} onChange={(e) => setDescription(e.target.value)} />
            <br />
            <button onClick={editProtocol}>Submit</button>
          </FormBody>
        </Form>
      </Modal>
    </>
  );
});

export default EditProtocol