import { observer } from 'mobx-react-lite';
import React from 'react';
import PropTypes from 'prop-types';
import FormSelectInput from "../SelectInput"


export const DateInput = observer(({
  label,
  value = '',
  readOnly,
  onChange,
  type,
  name
}) => (
  <div className="formInput">
    <label>{label}</label>
    <input
      name={name || ''}
      type={type ? type : "date"}
      value={value || ''}
      readOnly={readOnly}
      onChange={onChange}
    />
  </div>
));

export const IconButton = ({ icon, size = 35, color, primary, onClick, style = {} }) => {
  let divStyle = { height: size, width: size, ...style };
  let iStyle = { fontSize: `${10 + size * 0.2}px`, lineHeight: `${size}px` };
  if (!!color) divStyle.backgroundColor = color;
  return (
    <div className={`icon-button ${primary ? 'icon-button-primary' : ''}`} style={divStyle} onClick={onClick}>
      <i className={icon} style={iStyle} />
    </div>
  )
}

export const TextInput = observer(({
  label,
  value = '',
  readOnly,
  onChange,
  placeholder,
  type,
  inputProps,
  small,
  name
}) => (
  <div className="formInput">
    {label && (<label>{label}</label>)}
    <input
      name={name || ''}
      type={type || 'text'}
      value={value || ''}
      readOnly={readOnly}
      onChange={onChange}
      placeholder={placeholder}
      {...inputProps}
      style={Object.assign({}, (small ? { width: '88px', minWidth: '88px' } : {}))}
    />
  </div>
));

const childrenPropType = PropTypes.oneOfType([
  PropTypes.shape(),
  PropTypes.array,
]).isRequired;

const Form = ({ children, options }) => <form className="Form" {...options}>{children}</form>;

Form.propTypes = { children: childrenPropType };
export default Form;

export const FormBody = ({ children }) => <div className="formBody">{children}</div>;
FormBody.propTypes = { children: childrenPropType };

export const FormActions = ({ children }) => <div className="formActions">{children}</div>;
FormActions.propTypes = { children: childrenPropType };

const SelectInputBasic = observer(({
  label,
  value,
  options,
  onChange,
  fields
}) => (
  <div className="formInput">
    <label>{label}</label>
    <select value={!!value || value === 0 ? value : ''} onChange={onChange} style={{ minWidth: '160px' }}>
      <option value={''}>--</option>
      {options && options.map((option, i) => (
        <option
          key={`select-${label}-option-${option[fields.value]}-${i}`}
          value={option[fields.value]}
        >
          {option[fields.label]}
        </option>
      ))}
    </select>
  </div>
));

export const SelectInput = props => props.useBasic ? <SelectInputBasic {...props} /> : <FormSelectInput {...props} />;
