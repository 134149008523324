import ReactModal from "react-modal";
import "./cborModal.css"
import React, { useState, useContext } from "react";
import { RootContext } from "../../index";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Modal from "../Common/Modal";
import Form, { FormBody, TextInput} from '../Common/Form';
import { token } from '../../libs/localStorage';
import moment from "moment";
require ("moment-timezone");


const styles = {
	modal: {
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: "rgba(0, 0, 0, 0.75)",
			zIndex: 1000
		},
		content: {
			backgroundColor: "white",
			position: "fixed",
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)"
		}
	}
};

const Modal2 = ({ children, title, closeModal, referencetime,postData, retrieveData }) => {

	const rootStore = useContext(RootContext);
	const rawDataStore = rootStore.rawDataStore;
	const [showModal, setShowModal] = useState(false);
	const [refTime,setRefTime] =useState({
		referenceTime:''
	})

	const decodedData = token.getDecoded()


	const onRequestClose = (e) => {
		closeModal();
	};

	const setNewForm1 = (e) => {
		const { name, value } = e.target;
		setRefTime((prev) => ({ ...prev, [name]: value }));
};

	const handleClick = async (e) => {
		setShowModal(true)
		e.preventDefault()
	};


	const rePostData = async (e) => {
		setShowModal(true)
		e.preventDefault()
		const confirm = window.confirm("Do you want to repost data?");
		postData.referencetime = refTime.referenceTime || referencetime
		const data2 = await rawDataStore.autoPostDataPost(postData)
		if (data2.message == "Success") {
				closeModal()
				toast.success("Data Reposted SuccessFully");
				retrieveData(e)
		}
	};

	return (
		<ReactModal
			isOpen
			onRequestClose={onRequestClose}
			className="Modal2"
			style={styles.modal}
			contentLabel={title}
			appElement={document.getElementById("root")}
		>
			<div className="modalHeader2">
				<div className="modalHeaderTitle2">{title}</div>
				{/* <div className="refTime" >Reference Time(Local): {referencetime}</div> */}
				<div style={{marginRight:'10px'}} className="refTime" >Reference Time ( Local ): {referencetime}</div>
				{decodedData && decodedData.role == 'admin' ? <button style={{ maxWidth: "30%" }} onClick={(e) => handleClick(e)}>Repost Data</button> : []}

				{showModal &&    <Modal
                    title={"Post Data"}
                    closeModal={() => {
                        setShowModal(false);
												setRefTime(referencetime)
                    }}
                >
          <div className="main-content">

                        <Form>
                            <FormBody>
                                <TextInput label={'ReferenceTime'} name="referenceTime" value={refTime.referenceTime || referencetime} onChange={setNewForm1} />
																<button onClick={(e) => rePostData(e)}>Post</button>
                            </FormBody>
                        </Form>
                    </div>

                </Modal>}
				<span
					className="modalHeaderClose2"
					onClick={() => closeModal()}
				>
					<i className="fa fa-close" />
				</span>
			</div>
			<div className="modalChildren2">{children}</div>
		</ReactModal>
	);
};

export default Modal2;
