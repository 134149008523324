import PropTypes from 'prop-types';
import { useLocation,Link } from 'react-router-dom';

const TabBar = ({ items, rounded }) => {
  const location = useLocation();
  const path = location.pathname;

  return (
    <div className={`TabBar${rounded ? ` TabBarRounded` : ''}`}>
      <ul>
        {items &&
          items.map(({ label, to, exact, isVisible = true }) => (
            <li
              key={to}
              style={{ display: isVisible ? 'visible' : 'none' }}
              className={
                exact ? (path === to ? 'active' : '') : path.indexOf(to) === 0 ? 'active' : ''
              }
            >
              <Link to={to}  exact={exact}>
                {label}
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
};


TabBar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      exact: PropTypes.bool,
    })
  ).isRequired,
  rounded: PropTypes.bool,
};

export default TabBar;

