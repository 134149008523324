import React, { useState, useEffect } from "react";
import Modal from "../../Common/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../../..";
import { observer } from "mobx-react-lite";
import { DataTable } from "../../Common/DataTable/DataTable";
import { Outlet, useNavigate } from "react-router-dom";
import { Spinner } from "../../Common/Spinner";
import { DataTable3 } from "../../Server/DataTable3";
import Form, { FormBody, TextInput } from '../../Common/Form';

const Plan = observer(() => {
    const rootStore = useContext(RootContext);
    const planStore = rootStore.planStore;

    const [showAddPlan, setShowAddPlan] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);


    const navigate = useNavigate();

    useEffect(() => {
        if (!planStore.plan.length) planStore.fetchPlan();
    }, []);

    const [planInfo, setPlanInfo] = useState({
        caption: "",
        description: "",
        volume: ""
    });

    const setNewForm = (e) => {
        const { name, value } = e.target;
        setPlanInfo((prev) => ({ ...prev, [name]: value }));
    };


    const addPlan = async (e) => {
        e.preventDefault();
        if (!planInfo.caption || !planInfo.description || !planInfo.volume) {
            toast.error("Missing fields");
            return;
        }
        const addedPlanInfo = await planStore.addPlan(planInfo);

        if (!addedPlanInfo.id) {
            await planStore.fetchPlan();

            setPlanInfo({ caption: "", description: "" });
        }

        setShowAddPlan(false)
    };


    const deleteSelectedPlans = async (e) => {
        const confirm = window.confirm("Delete selected plans?");
        if (selectedRows.length != 0 && confirm) {
            await Promise.all(selectedRows.map((id) => planStore.deletePlan(id)));
            setSelectedRows([]);
        } else if (confirm) {
            await planStore.deletePlan(e);
        }
    };

    const toggleCheckbox = (id) => {
        const isSelected = selectedRows.includes(id);

        if (isSelected) {
            setSelectedRows((prevRows) => prevRows.filter((rowId) => rowId !== id));
        } else {
            setSelectedRows((prevRows) => [...prevRows, id]);
        }
    };



    const handleCheckboxChange = (id) => {
        const isSelected = selectedRows.includes(id);

        if (isSelected) {
            setSelectedRows((prevRows) => prevRows.filter((rowId) => rowId !== id));
        } else {
            setSelectedRows((prevRows) => [...prevRows, id]);
        }

        handleCheckboxChange && handleCheckboxChange(selectedRows);
    };

    return (
        <div>
            {planStore.loading ? (
                <Spinner />
            ) : (
                <DataTable3
                    title="Plan List"
                    data={planStore.plan
                        .map((item, index) => {
                            return {
                                ...item,
                                SN: index + 1,
                            }
                        })

                    }
                    headers={[
                        { key: "SN", label: "SN" },
                        { key: "caption", label: "Caption" },
                        { key: "description", label: "Description" },
                        // { key: "volume", label: "Volume(kb)" }
                    ]}
                    handleAdd={() => setShowAddPlan(true)}
                    handleEdit={(id) => {
                        navigate(`/configuration/plan/${id}`);
                    }}
                    handleDelete={(e) => deleteSelectedPlans(e)}
                    handleCheckboxChange={(id) => toggleCheckbox(id, selectedRows, setSelectedRows)}
                />)}

            {showAddPlan && (
                <Modal
                    title={"Add Plan"}
                    closeModal={() => {
                        setShowAddPlan(false);
                        setPlanInfo({ caption: "", description: "", volume: "" });
                    }}
                >

                    <Form>
                        <FormBody>
                            <TextInput label={'Caption'} name="caption" value={planInfo.caption} onChange={setNewForm} />
                            <TextInput label={'Description'} name="description" value={planInfo.description} onChange={setNewForm} />
                            <TextInput label={'Volume'} name="volume" value={planInfo.volume} onChange={setNewForm} />
                            <button onClick={(e) => addPlan(e)}>Submit</button>
                        </FormBody>
                    </Form>
                </Modal>
            )}
            <Outlet />
        </div>

    );
});


export default Plan
