import { makeAutoObservable, runInAction } from "mobx";
import { callApi } from "../actions/apiMethods";

export class dataUsageStore{
  items = []
	folders = []
	usageDetailsById = []
	simUsageDetailsById = []
	infoBalVol = []

  constructor() {
		makeAutoObservable(this);
	}

  fetchItems = async () => {
		const response = await callApi("GET", "api/dataUsage");
		if (!response) return;
		runInAction(() => {
			this.items = response.details;
		});
	};

	fetchUsageById = async (deviceid) => {
		const response = await callApi("GET", `api/dataUsage/${deviceid}`);
		if (!response) return;
		runInAction(() => {
			this.usageDetailsById = response.details;
		});
	};

	fetchSimUsageById = async (simNumber) => {
		const response = await callApi("GET", `api/dataUsageSim/${simNumber}`);
		if (!response) return;
		runInAction(() => {
			this.simUsageDetailsById = response.details;
		});
		return response.details
	};

	fetchFolders = async () => {
		const response = await callApi("GET", "api/folders");
		if (!response) return;
		runInAction(() => {
			this.folders = response.details;
		});
	};

	fetchInfoBalVol = async () => {
		const response = await callApi("GET", "api/infobalvol");
		if (!response) return;
		runInAction(() => {
			this.infoBalVol = response.details;
		});
	};


}