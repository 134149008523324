import { makeAutoObservable, runInAction } from "mobx";
import { callApi } from "../actions/apiMethods";
import { toast } from "react-toastify";

export class serverStore {
	servers = [];
	device2server = [];
	loading = false;

	constructor() {
		makeAutoObservable(this);
	}
	
	fetchServers = async () => {
		this.loading = true;
		const response = await callApi("GET", "api/server");
		if (!response) return;
		runInAction(() => {
			this.loading = false;
			this.servers = response.servers;
		});
	};

	addServer = async (formEntries) => {
		const response = await callApi("POST", "api/server", formEntries);
		if (!response) return;
		runInAction(() => {
			this.servers.push(formEntries);
			this.loading = false;
		});
		toast.success(response.message);
		return response.message;
	};

	editServer = async (id, serverInfo) => {
		const response = await callApi("PUT", `api/server/${id}`, serverInfo);
		if (!response) return;
		runInAction(() => {
			this.servers = this.servers.map((server) => (server.id === id ? { ...server, ...serverInfo } : server));
			this.loading = false;
		});
		toast.success(response.message);
		return response.message;
	};

	deleteServer = async (serverid) => {
		const response = await callApi("DELETE", `api/server/${serverid}`);
		if (!response) return;
		runInAction(() => {
			this.servers = this.servers.filter((server) => server.id !== serverid);
			this.loading = false;
		});
		toast.success("Server deleted");
	};

	 adddevice2server = async (formEntries) => {
		const response = await callApi("POST", "api/device2server", formEntries);
		if (!response) return;
		runInAction(() => {
			this.device2server.push(formEntries);
			this.loading = false;
		});
		toast.success(response.message);
		return response.details;
	};

	fetchDevice2Server = async () => {
		this.loading = true;
		const response = await callApi("GET", "api/device2server");
		if (!response) return;
		runInAction(() => {
			this.loading = false;
			this.device2server = response.details;
		});
	};

	editDevice2Server = async (id, serverInfo) => {
		const response = await callApi("PUT", `api/device2server/${id}`, serverInfo);
		if (!response) return;
		runInAction(() => {
			this.device2server = this.device2server.map((server) => (server.id === id ? { ...server, ...serverInfo } : server));
			this.loading = false;
		});
		toast.success(response.message);
		return response.message;
	};


	deleteDevice2Server = async (serverid) => {
		const response = await callApi("DELETE", `api/device2server/${serverid}`);
		if (!response) return;
		runInAction(() => {
			this.device2server = this.device2server.filter((server) => server.id !== serverid);
			this.loading = false;
		});
		toast.success("Device to server url deleted");
	};

}
