import React, { useState, useEffect, useContext} from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Cart from "./Cart";
import ItemsDragLayer from "./ItemsDragLayer";
import { observer } from "mobx-react-lite";
import { RootContext } from "../../index";
import { useParams, useNavigate } from "react-router-dom";
import "./dnd.css"

const styles = {
  main: {
    width: "30%",
    margin: "0 auto",
    marginLeft:"10px",
    textAlign: "center"
  },
  content: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "left",
    alignItems: "stretch",
    alignContent: "stretch"
  }
};

const   DragAndDrop = observer(({leftList,rightList,metaDataTempId,caption,description}) => {
  const rootStore = useContext(RootContext);
  const tagStore = rootStore.tagStore;

  const navigate = useNavigate();

  const list2 = leftList.map((data) => data.name)
  const list1 = rightList.map((data) => data.name)

  const [leftItems, setLeftItems] = useState([]);
  const [rightItems, setRightItems] = useState([]);

  useEffect(() => {
    setLeftItems(list1)
    setRightItems(list2)
  },[leftList,rightList])

  useEffect(() => {
    tagStore.fetchMetaData()
  },[])

  const addItemsToCart = (items, source, dropResult) => {
    const newLeftItems =
      source == "left"
        ? leftItems?.filter((x) => items.findIndex((y) => x === y) < 0)
        : leftItems?.concat(items);
    const newRightItems =
      source == "left"
        ? rightItems?.concat(items)
        : rightItems?.filter((x) => items.findIndex((y) => x === y) < 0);
    setLeftItems(newLeftItems);
    setRightItems(newRightItems);
  };

  const editTemplate = async (e) => {
    e.preventDefault();
    const metadata = tagStore.meta_data
    // .filter(item => item !== undefined);

    const items1 = leftItems.map((data)=> metadata.find((item1)=>data == item1.name)).map((data,index)=> {
      const name = data.name
      const id = data.id
      const seq = index + 1
      const metaDataTemplateId = metaDataTempId
      return {name,id,seq, metaDataTemplateId}
      }
    )
    const items2 = rightItems.map((data)=> metadata.find((item2)=>data == item2.name)).map((data,index)=> {
      const name = data.name
      const id = data.id
      const seq = index + 1
      const metaDataTemplateId = metaDataTempId
      return {name,id,seq,metaDataTemplateId}
      }
    )

    const divInfo = {caption,description}

    await tagStore.addEditMetaDataTemplate(items2);
    await tagStore.fetchMetaDataTemplate()
    await tagStore.editMetaDataTemplate(metaDataTempId,divInfo)
    await tagStore.fetchMetaDataTemplate();
    navigate("/configuration/metadata/metadatatemplate")

  };


  return (
    <div style={styles.main}>
      <DndProvider backend={HTML5Backend}>
        <ItemsDragLayer />
        <div className="dnd">
          <div className="dndList">
          <div className="dndList-header">Meta Data List</div>
          <Cart id="left" fields={leftItems} addItemsToCart={addItemsToCart} />
          </div>
         
          <div className="dndList">
          <div className="dndList-header">Template Meta Data</div>
          <Cart
            id="right"
            fields={rightItems}
            addItemsToCart={addItemsToCart}
          />
          </div>
          
        </div>
      </DndProvider>
      <button onClick={editTemplate}>Submit</button>
    </div>
  );
})
export default DragAndDrop