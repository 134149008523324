import React, { useState, useEffect } from "react";
import Modal from "../Common/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../../../src/index";
import { observer } from "mobx-react-lite";
import { useParams, useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ServerModal from "./ServerModal";
import InputLabel from "@mui/material/InputLabel";


const EditDevice = observer(() => {
  const rootStore = useContext(RootContext);
  const deviceStore = rootStore.deviceStore;
  const planStore = rootStore.planStore;
  const serviceProviderStore = rootStore.serviceProviderStore;
  const serverStore = rootStore.serverStore
  const simDetailStore = rootStore.simDetailStore

  const [serialNumber, setSerialNumber] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [modelNo, setModelNo] = useState("");
  const [planType, setPlanType] = useState("");
  const [serviceProvider, setServiceProvider] = useState("");
  const [protocol, setProtocol] = useState("");
  const [addedServerOptions, setAddedServerOptions] = useState([])
  const [showAddServer, setShowAddServer] = useState(false);
  const [showAddDevice, setShowAddDevice] = useState(false);

  const [puk1, setpuk1] = useState("");
  const [puk2, setpuk2] = useState("");
  const [pin1, setpin1] = useState("");
  const [pin2, setpin2] = useState("");
  const [purchasedate, setpurchasedate] = useState("");
  const [loginpassword, setloginpassword] = useState("");
  const [planId, setplanId] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    deviceStore.fetchdevice();
    deviceStore.fetchdevice2server()
    planStore.fetchPlan()
    serviceProviderStore.fetchServiceProvider()
    serverStore.fetchServers();
    simDetailStore.fetchOnlySimDetail()


    const currentDevice = deviceStore.device.find((device) => device.id === id);
    const currentSim = simDetailStore.details4.find((sim) => sim.device_id == id)

    setSerialNumber(currentDevice?.serial_no)
    setDeviceId(currentDevice?.device_id)
    setModelNo(currentDevice?.model_no)
    setPlanType(currentDevice?.plan_id)
    setServiceProvider(currentDevice?.service_provider_id)
    setProtocol(currentDevice?.protocol_id)
    setplanId(currentDevice?.plan_id)

    if (currentDevice.service_provider_id == 18 || currentDevice.service_provider_id == 19) {
        setpuk1(currentSim?.puk1);
        setpuk2(currentSim?.puk2);
        setpin1(currentSim?.pin1);
        setpin2(currentSim?.pin2);
        setpurchasedate(currentSim?.purchasedate.split("T")[0]);
        setloginpassword(currentSim?.loginpassword);
    }


    const serverSelection = deviceStore.items.filter((device) => device.device_id == currentDevice.device_id)
    const serverId = serverSelection.map((data) => data.server_id)

    const serverDomainUrl = serverId.map((data) => {
      const value = serverStore.servers.find((server) => server.id == data)
      return value ? value.domain_name : ""
    })

    const requiredData = serverDomainUrl.map((data) => {
      return { serverSelection: data }
    })

    setAddedServerOptions(requiredData)


  }, []);

  const removeServerOption = (index) => {
    const updatedServerOptions = addedServerOptions.slice();
    updatedServerOptions.splice(index, 1);
    setAddedServerOptions(updatedServerOptions);
  };

  const showServerForm = (e) => {
    e.preventDefault()
    setShowAddServer(true);
  }

  const setNewAddedServerInfo = (e) => {
    const { name, value } = e.target;
    setAddedServerOptions((prev) => [...prev, { [name]: value }]);

  };


  const onSubmit = async (e) => {
    e.preventDefault()

    try {
      const updateDevice = await deviceStore.editDevice(id, { serial_no: serialNumber, device_id: deviceId, model_no: modelNo, plan_id: planType, service_provider_id: serviceProvider, protocol_id: protocol })

      if (puk1 || puk2 || pin1 || pin2 || purchasedate || loginpassword) {
        const updateSim = await simDetailStore.editSimDetails(id, { phonenumber: deviceId, puk1, puk2, pin1, pin2, purchasedate, loginpassword })
      }
    
      const devicesWithCounter = deviceStore.items.filter(device => device.counter !== null && device.counter !== undefined)[0].counter;
      const deleteDevices = await deviceStore.deleteDevice2(deviceId)

      const addDevicePromises = addedServerOptions.map(async (serverOption) => {
        const serverId = getServerId(serverOption.serverSelection);
        const adddevice = await deviceStore.addDevice2Server({ serverId, deviceId, counter: devicesWithCounter });
        return adddevice
      });

      await Promise.all(addDevicePromises);

      await deviceStore.fetchdevice();
      await deviceStore.fetchdevice2server()
      await simDetailStore.fetchOnlySimDetail()

      navigate("/monitoring/dashboard");
      setShowAddDevice(false)
      toast.success("Edited")
    } catch (error) {
      console.log(error, "error")
    }

  }

  const getServerId = (serverName) => {
    const value = serverStore.servers.find((server) => server.domain_name == serverName)
    return value ? value.id : ""
  }


  return (
    <>
      <Modal
        title={"Edit Device details"}
        closeModal={() => {
          navigate("/monitoring/dashboard");
        }}
      >

        <form className="Forms">
          <div className="main-content">
            <div className="text-field">

              <label>Serial Number</label>
              <input
                type="text"
                name="serialNumber"
                value={serialNumber}
                onChange={(e) => setSerialNumber(e.target.value)}
              />

              <label>Device Id</label>
              <input
                type="text"
                name="deviceId"
                value={deviceId}
                onChange={(e) => setDeviceId(e.target.value)}
              />

              <label>Model No.</label>
              <input
                type="text"
                name="modelNo"
                value={modelNo}
                onChange={(e) => setModelNo(e.target.value)}
              />

              <label>Plan Type</label>
              <FormControl>
                <Select
                  defaultValue=""
                  name="planType"
                  // label="serverSelection"
                  value={planType}
                  onChange={(e) => setPlanType(e.target.value)}
                  sx={{
                    width: 500,
                    height: 40,
                    backgroundColor: "white",
                    margin: 0
                  }}
                >
                  {deviceStore.plan.map((plan, index) => (
                    <MenuItem key={index} value={plan.id}>{plan.caption}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <label>Service Provider</label>
              <FormControl >
                {/* <InputLabel id="demo-simple-select-label">Service Provider</InputLabel> */}
                <Select
                  defaultValue=""
                  name="serviceProvider"
                  // label="serverSelection"
                  value={serviceProvider}
                  onChange={(e) => setPlanType(e.target.value)}
                  sx={{
                    width: 500,
                    height: 40,
                    backgroundColor: "white",
                    margin: 0
                  }}
                >
                  {deviceStore.serviceProvider.map((provider, index) => (
                    <MenuItem key={index} value={provider.id}>{provider.caption}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <label>Protocol</label>
              <FormControl >
                <Select
                  defaultValue=""
                  name="protocol"
                  // label="serverSelection"
                  value={protocol}
                  onChange={(e) => setProtocol(e.target.value)}
                  sx={{
                    width: 500,
                    height: 40,
                    backgroundColor: "white",
                    margin: 0,
                  }}
                >
                  {deviceStore.protocol.map((protocol, index) => (
                    <MenuItem key={index} value={protocol.id}>{protocol.caption}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="secondary-content">
            <div className="text-field2">
              {
                (serviceProvider == "18" || serviceProvider == 19) && (
                  <>
                    <label>Puk1</label>
                    <input
                      type="text"
                      name="puk1"
                      value={puk1}
                      onChange={(e) => setpuk1(e.target.value)}
                    />
                    <label>Puk2</label>
                    <input
                      type="text"
                      name="puk2"
                      value={puk2}
                      onChange={(e) => setpuk2(e.target.value)}
                    />
                    <label>Pin1</label>
                    <input
                      type="text"
                      name="pin1"
                      value={pin1}
                      onChange={(e) => setpin1(e.target.value)}
                    />
                    <label>Pin2</label>
                    <input
                      type="text"
                      name="pin2"
                      value={pin2}
                      onChange={(e) => setpin2(e.target.value)}
                    />
                    <label>Purchasedate</label>
                    <input
                      type="date"
                      name="purchasedate"
                      value={purchasedate}
                      onChange={(e) => setpurchasedate(e.target.value)}
                    />
                    <label>Login Password </label>
                    <input
                      type="text"
                      name="loginpassword"
                      value={loginpassword}
                      onChange={(e) => setloginpassword(e.target.value)}
                    />
                  </>
                )}
            </div>

          </div>


          <br />
        </form>


        <div>
          <div className="addDevice">
            <div className="addServerOptions" >
              {addedServerOptions.map((serverOption, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                  <div>{serverOption.serverSelection}</div>
                  <button
                    onClick={() => removeServerOption(index)}
                    style={{ position: 'relative', top: '0', right: '0', fontSize: '6px', padding: '2px', cursor: 'pointer' }}>X</button>
                </div>
              ))}
            </div>
            <button onClick={(e) => showServerForm(e)}>Add Server</button>
          </div>

          <div className="saveAndcancel">
            <button onClick={(e) => onSubmit(e)}>Submit</button>
            {/* <button onClick={(e) => setShowAddDevice(false)}>Cancel</button> */}
          </div>

        </div>
      </Modal>

      {showAddServer && (
        <ServerModal
          title={"Add Device"}
          closeModal={() => {
            setShowAddServer(false);
          }}
        >
          <form
            className="Form"
            autoComplete="off"
          >
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-label">Server</InputLabel>
              <Select
                defaultValue=""
                name="serverSelection"
                label="serverSelection"
                value={addedServerOptions.serverSelection}
                onChange={setNewAddedServerInfo}
              >
                {serverStore.servers.map((server, index) => (
                  <MenuItem key={server.id} value={server.domain_name}>{server.domain_name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <button onClick={(e) => addServer(e)}>Add</button> */}
          </form>

        </ServerModal>
      )}
    </>

  );
});

export default EditDevice