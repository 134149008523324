import React, { useState } from "react";
import "../../RawData/Table.css";
import {useNavigate } from "react-router-dom";

export const BalanceTable = ({ headers, data }) => {
	const navigate = useNavigate();


  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  const sortedData = React.useMemo(() => {
    let sortableData = [...data];

    if (sortConfig !== null && sortConfig.key == "Balance" ) {
      sortableData.sort((a, b) => {
        let aValue = parseFloat(a['remaining_balance']);
        let bValue = parseFloat(b['remaining_balance']);

        if (aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    } else if (sortConfig !== null && sortConfig.key == "Volume") {
      sortableData.sort((a, b) => {
        let aValue = a['remaining_volume'];
        let bValue = b['remaining_volume'];

        if (aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    } 

    return sortableData.map((data, index) => {
      let SN = index + 1;
      let Number = data.phonenumber;
      let Balance = data.remaining_balance;
      let Volume = data.remaining_volume;

      return { SN, Number, Balance, Volume };
    });
  }, [data, sortConfig]);

  const requestSort = key => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

	// const pageRedirect = (e) => {
	// navigate(`/singledevicedetail/sim/metadata/${e}`);
	// }

  const pageRedirect = (phoneNumber) => {
    const url = `${window.location.origin}/#/singledevicedetail/sim/metadata/${phoneNumber}`;
    window.open(url, "_blank"); // Opens the URL in a new tab
  };
  

  return (
    <div className="data-table-container-rawdata">
      <table className="data-table-rawdata">
        <thead>
          <tr style={{ cursor: 'pointer' }}>
            {headers.map((title, index) => (
              <th key={index} onClick={() => (title == 'Balance' || title == "Volume")  && requestSort(title)}>
              {title} {(title === 'Balance' || title === 'Volume') && <i class="fa fa-sort" aria-hidden="true"></i>}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData &&
            sortedData.map((data, index) => (
              <tr key={index}>
                {headers.map((h, i) => (
                  <td
                    style={{ padding: "1px", paddingLeft: "5px", fontSize: "1em" }}
                    key={i}
										onClick={(e) => pageRedirect(data['Number'])}
                  >
                    {data[h]}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
