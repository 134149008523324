import { makeAutoObservable, runInAction } from "mobx";
import { callApi } from "../actions/apiMethods";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export class serviceProviderStore{
  serviceProvider = []

  constructor() {
		makeAutoObservable(this);
	}

	addServiceProvider = async (formEntries) => {
		const response = await callApi("POST", "api/serviceProvider", formEntries);
		if (!response) return;
		runInAction(() => {
			this.serviceProvider.push(formEntries);
			this.loading = false;
		});
		toast.success(response.message);
		return response.message;
	};

  fetchServiceProvider = async () => {
		const response = await callApi("GET", "api/serviceProvider");
		if (!response) return;
		runInAction(() => {
			this.serviceProvider = response.details;
		});
	};

	editServiceProvider = async (id, serviceProviderInfo) => {
		const response = await callApi("PUT", `api/serviceProvider/${id}`, serviceProviderInfo);
		if (!response) return;
		runInAction(() => {
			this.serviceProvider = this.serviceProvider.map((provider) => (provider.id === id ? { ...provider, ...serviceProviderInfo } : provider));
			this.loading = false;
		});
		toast.success(response.serviceProvider);
		return response.serviceProvider;
	};

	deleteServiceProvider = async (serviceProviderId) => {
		const response = await callApi("DELETE", `api/serviceProvider/${serviceProviderId}`);
		if (!response) return;
		runInAction(() => {
			this.serviceProvider = this.serviceProvider.filter((provider) => provider.id !== serviceProviderId);
			this.loading = false;
		});
		toast.success("Service Provider deleted");
	};

}