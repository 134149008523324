import React, { useState, useEffect } from "react";
import Modal from "../Common/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../../index";
import { observer } from "mobx-react-lite";
import { Outlet, useNavigate } from "react-router-dom";
import { Spinner } from "../Common/Spinner";
import { DataTable3 } from "../Server/DataTable3";
import Form, { FormBody, TextInput} from '../Common/Form';

const Account = observer(() => {
    const rootStore = useContext(RootContext);
    const deviceStore = rootStore.deviceStore;

    const [showAddAcount, setshowAddAcount] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);


    const navigate = useNavigate();

    useEffect(() => {
        if (!deviceStore.account.length) deviceStore.fetchAccount();
    }, []);

    const [accountInfo, setAccountInfo] = useState({
        caption: "",
        description: ""
    });

    const setNewForm = (e) => {
        const { name, value } = e.target;
        setAccountInfo((prev) => ({ ...prev, [name]: value }));
    };


    const addAccount = async (e) => {
        e.preventDefault();
        if (!accountInfo.caption || !accountInfo.description) {
            toast.error("Missing fields");
            return;
        }
        const addedaccountInfo = await deviceStore.addAccount(accountInfo);

        if (!addedaccountInfo.id) {
            setAccountInfo({ caption: "", description: "" });
        }
        await deviceStore.fetchAccount();
        setshowAddAcount(false)
    };


    const deleteSelectedAccounts = async (e) => {
        const confirm = window.confirm("Delete Selected Accounts?");
        if (selectedRows.length != 0  && confirm) {
          await Promise.all(selectedRows.map((id) => deviceStore.deleteAccount(id)));
          setSelectedRows([]); 
        } else if (confirm) {
            await deviceStore.deleteAccount(e);
        }
      };

      const toggleCheckbox = (id) => {
        const isSelected = selectedRows.includes(id);
        
        if (isSelected) {
          setSelectedRows((prevRows) => prevRows.filter((rowId) => rowId !== id));
        } else {
          setSelectedRows((prevRows) => [...prevRows, id]);
        }
      };
      


      const handleCheckboxChange = (id) => {
        const isSelected = selectedRows.includes(id);
      
        if (isSelected) {
          setSelectedRows((prevRows) => prevRows.filter((rowId) => rowId !== id));
        } else {
          setSelectedRows((prevRows) => [...prevRows, id]);
        }
      
        handleCheckboxChange && handleCheckboxChange(selectedRows);
      };
      
    return (
        <div>
            {deviceStore.loading ? (
                <Spinner />
            ) : (
                <DataTable3
                    title="Account List"
                    data={deviceStore.account
                        .map((item, index) => {
                            return {
                                ...item,
                                SN: index + 1,
                            }
                        })

                    }
                    headers={[
                        { key: "SN", label: "SN" },
                        { key: "accountid", label: "Account" }
                    ]}
                    handleAdd={() => setshowAddAcount(true)}
                    handleDelete={(e) => deleteSelectedAccounts(e)}
                    handleCheckboxChange={(id) => toggleCheckbox(id, selectedRows, setSelectedRows)}
                />)}

            {showAddAcount && (
                <Modal
                    title={"Add Account"}
                    closeModal={() => {
                        setshowAddAcount(false);
                        setAccountInfo({ caption: "", description: "" });
                    }}
                >
                     <Form>
                        <FormBody>
                            <TextInput label={'Caption'} name="caption"   value={accountInfo.caption} onChange={setNewForm} />
                            <TextInput label={'Description'} name="description"  value={accountInfo.description} onChange={setNewForm} />
                            <br/>
                            <button onClick={(e) => addAccount(e)}>Submit</button>
                        </FormBody>
                    </Form>

                </Modal>
            )}
            <Outlet />
        </div>

    );
});


export default Account
