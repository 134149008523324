import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../../../index";
import { observer } from "mobx-react-lite";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import Modal from "../../Common/Modal";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import { DataTable3 } from "../../Server/DataTable3";

const TriggerDevice = observer(() => {
  const rootStore = useContext(RootContext);
  const deviceStore = rootStore.deviceStore;

  const [showAddDevice, setShowAddDevice] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState({
    trigger_source_identifier: "",
    message_packet_type:"",
    audio_track_no: "",
    msg_timestamp: "",
    audio_volume: "",
    audio_repeat: "",
    audio_repeat_delay: ""
  });

  const [addedServerOptions, setAddedServerOptions] = useState([])
  const [selectedRows, setSelectedRows] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    deviceStore.fetchDataToDevice();
    deviceStore.fetchMin()
  }, []);

  const setNewForm = (e) => {
    const { name, value } = e.target;
    setDeviceInfo((prev) => ({ ...prev, [name]: value }));
  };


  const deleteDevice = async (id) => {
    const confirm = window.confirm("Delete?");
    if (confirm) {
      await Promise.all(selectedRows.map((id) => deviceStore.deleteDataToDevice(id)));
      setSelectedRows([]);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault()

    if (!deviceInfo.trigger_source_identifier || !deviceInfo.audio_track_no) {
      toast.error("Missing fields");
      return;
    }

    const addDataToDevice = await deviceStore.addDataToDevice(deviceInfo)

    deviceStore.fetchDataToDevice()
    setDeviceInfo({ trigger_source_identifier: "", message_packet_type:"",audio_track_no: "", msg_timestamp: "", audio_volume: "", audio_repeat: "", audio_repeat_delay: "" });

    setShowAddDevice(false)
  }

  const toggleCheckbox = (id) => {
    const isSelected = selectedRows.includes(id);

    if (isSelected) {
      setSelectedRows((prevRows) => prevRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows((prevRows) => [...prevRows, id]);
    }
  };



  const handleCheckboxChange = (id) => {
    const isSelected = selectedRows.includes(id);

    if (isSelected) {
      setSelectedRows((prevRows) => prevRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows((prevRows) => [...prevRows, id]);
    }

    handleCheckboxChange && handleCheckboxChange(selectedRows);
  };

  return (
    <div>
      <DataTable3
        title="Devices"
        data={deviceStore.items2
          .map((item, index) => {
            return {
              ...item,
              SN: index + 1,
            }
          })

        }
        handleAdd={() => setShowAddDevice(true)}
        headers={[
          { key: "SN", label: "SN" },
          { key: "trigger_source_identifier", label: "Name" },
          { key: "audio_track_no", label: "Audio Track Number" },
          { key: "msg_timestamp", label: "Time Stamp" },
          { key: "audio_volume", label: "Audio Volume(%)" },
          { key: "audio_repeat", label: "Audio Repeat Time" },
          { key: "audio_repeat_delay", label: "Audio Repeat Delay" }
        ]}
        handleDelete={deleteDevice}
        handleCheckboxChange={(id) => toggleCheckbox(id, selectedRows, setSelectedRows)}
      // onRowClick={handleRowClick}
      />

      {showAddDevice && (
        <Modal
          title={"Trigger Device"}
          closeModal={() => {
            setShowAddDevice(false);
            setDeviceInfo({ trigger_source_identifier: "",message_packet_type: "" ,audio_track_no: "", msg_timestamp: "", audio_volume: "", audio_repeat: "", audio_repeat_delay: "" });
            setAddedServerOptions([])
          }}
        >
          <form className="Form">
            <label>Name</label>
            <input
              type="text"
              name="trigger_source_identifier"
              value={deviceInfo.trigger_source_identifier}
              onChange={setNewForm}
            />
            <label>Message Packet Type</label>
            <FormControl>
              <Select
                defaultValue=""
                name="message_packet_type"
                // label="serverSelection"
                value={deviceInfo.message_packet_type}
                onChange={setNewForm}
                sx={{
                  width: 490,
                  height: 40,
                  backgroundColor: "white",
                  margin: 1
                }}
              >
                {deviceStore.min.map((min, index) => (
                  <MenuItem key={index} value={min.value}>{min.min_type}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <label>Time Stamp</label>
            <input
              type="number"
              name="msg_timestamp"
              value={deviceInfo.msg_timestamp}
              onChange={setNewForm}
            />

            <label>Audio Volume</label>
            <input
              type="number"
              name="audio_volume"
              placeholder="0 to 100"
              value={deviceInfo.audio_volume}
              onChange={setNewForm}
            />

            <label>Audio Track No</label>
            <input
              type="number"
              name="audio_track_no"
              placeholder="1 to 5"
              value={deviceInfo.audio_track_no}
              onChange={setNewForm}
            />

            <label>Audio Repeat</label>
            <input
              type="number"
              name="audio_repeat"
              placeholder="1 to 100 times"
              value={deviceInfo.audio_repeat}
              onChange={setNewForm}
            />

            <label>Audio Delay</label>
            <input
              type="number"
              name="audio_repeat_delay"
              placeholder="1 to 100 "
              value={deviceInfo.audio_repeat_delay}
              onChange={setNewForm}
            />


            <br />
          </form>
          <div>
            <div className="saveAndcancel">
              <button onClick={(e) => onSubmit(e)}>Submit</button>
              <button onClick={(e) => setShowAddDevice(false)}>Cancel</button>
            </div>

          </div>

        </Modal>
      )}
      <br />
      <Outlet />
    </div>
  );
});


export default TriggerDevice