import ClipLoader from "react-spinners/ClipLoader";

export const Spinner = () => {
	return (
			<ClipLoader
				color="#3C8DBC"
				size={100}
				cssOverride={{ display: "block", margin: "auto" }}
			/>
	);
};
