import React from "react";

const getFieldStyle = (isDragging) => {
  const style = {
    margin:"0.5em",
    padding: "0.5em",
    backgroundColor: "#fdfdfd",
    boxShadow: "0 0 0.3em 0.03em rgba(0,0,0,0.3)"
  };
  style.opacity = isDragging ? 1 : 1;
  return style;
};

const ItemsTemplate = ({ fields }) => {
  const rows = fields.map((field) => (
    <div key={field} style={getFieldStyle(false)}>
      {field}
    </div>
  ));
  return <div>{rows}</div>;
};

export default ItemsTemplate;
