import React, { useState, useEffect } from "react";
import Modal from "../Common/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../..";
import { observer } from "mobx-react-lite";
import { useParams, useNavigate } from "react-router-dom";

export const EditServer = observer(() => {
	const rootStore = useContext(RootContext);
	const serverStore = rootStore.serverStore;

	const [server, setServer] = useState("");
	const [caption, setCaption] = useState("");
	const [description, setDescription] = useState("");

	const { id } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (!serverStore.servers.length) serverStore.fetchServers();
		const currentserver = serverStore.servers.find((server) => server.id === id);
		setCaption(currentserver?.caption);
		setDescription(currentserver?.description);
		setServer(currentserver?.domain_name);
	}, []);

	const editUserServer = async (e) => {
		e.preventDefault();
		if (!caption || !description) {
			toast.error("Missing fields");
			return;
		}
		const result = serverStore.editServer(id, { caption: caption, description: description });
		if (result) navigate("/devices/server");
	};

	return (
		<>
			<Modal
				title={"Edit server details"}
				closeModal={() => {
					navigate("/devices/server");
				}}
			>
				<form
					className="Form"
					autoComplete="off"
				>
					<label>Domain name</label>
					<input
						type="text"
						value={server}
						readOnly
					/>

					<label>Caption</label>
					<input
						type="text"
						value={caption}
						autoComplete="off"
						onChange={(e) => setCaption(e.target.value)}
					/>

					<label>Description</label>
					<input
						type="text"
						value={description}
						autoComplete="off"
						onChange={(e) => setDescription(e.target.value)}
					/>
					<br />
					<button onClick={editUserServer}>Submit</button>
				</form>
			</Modal>
		</>
	);
});
