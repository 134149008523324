import { makeAutoObservable, runInAction } from "mobx";
import { callApi } from "../actions/apiMethods";

export class rawDataStore{
  items = []
	items2 = []
	convertedData = []
	time = []
	orbcommDevice = []
	autoPostData = ""

  constructor() {
		makeAutoObservable(this);
	}

  fetchRawData = async (entries) => {
		const response = await callApi("POST", "api/raw_datas", entries);
		if (!response) return;
		runInAction(() => {
			this.items = response.details;
		});
	};

	fetchOrbcommDevice = async () => {
		const response = await callApi("GET", "api/orbcommdevice");
		if (!response) return;
		runInAction(() => {
			this.orbcommDevice = response.details;
		});
	};


	fetchDecodedData = async (entries) => {
		const response = await callApi("POST", "api/decoded_data",entries);
		if (!response) return;
		runInAction(() => {
			this.items2 = response.details;
		});
	};

	fetchReferenceTime = async (entries) => {
		const response = await callApi("POST", "api/time",entries);
		if (!response) return;
		runInAction(() => {
			this.time = response.details;
		});
	};


	fetchConvertedData = async (userData) => {
		let obj = {
			data:userData
		}
		const response = await callApi("POST", "api/convertedData",obj);
		if (!response) return;
		runInAction(() => {
			this.convertedData = response;
		});
	};


	autoPostDataPost = async (entries) => {
		const response = await callApi("POST", "api/autoPostData",entries);
    if  (!response) return;
		return response
	};


}

