import React, { useState, useEffect } from "react";
import Modal from "../Common/Modal";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../..";
import { observer } from "mobx-react-lite";
import { DataTable } from "../Common/DataTable/DataTable";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { Spinner } from "../Common/Spinner";
import { DataTable3 } from "./DataTable3";

const ServerList = observer(() => {
    const rootStore = useContext(RootContext);
    const serverStore = rootStore.serverStore;

    const [showAddServer, setShowAddServer] = useState(false);
    const [showAddRedirectingServer, setShowAddRedirectingServer] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);


    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        serverStore.fetchServers();
        serverStore.fetchDevice2Server();
    }, []);

    const [serverInfo, setServerInfo] = useState({
        caption: "",
        description: "",
        domain_name: ""
    });

    const [redirectServerInfo, setReDirectServerInfo] = useState({
        device_id: "",
        server_id: ""
    });

    const setNewServerInfo = (e) => {
        const { name, value } = e.target;
        setReDirectServerInfo((prev) => ({ ...prev, [name]: value }));
    };

    const setNewForm = (e) => {
        const { name, value } = e.target;
        setServerInfo((prev) => ({ ...prev, [name]: value }));
    };

    const addRedirectingServer = async (e) => {
        e.preventDefault();
        if (!redirectServerInfo.device_id || !redirectServerInfo.server_id) {
            toast.error("Missing fields");
            return;
        }
        const result = await serverStore.adddevice2server(redirectServerInfo);
        await serverStore.fetchDevice2Server();

        setReDirectServerInfo({ device_id: "", server_id: "" });
        if (result) setShowAddRedirectingServer(false);

    };


    const addServer = async (e) => {
        e.preventDefault();
        if (!serverInfo.caption || !serverInfo.domain_name || !serverInfo.description) {
            toast.error("Missing fields");
            return;
        }
        const addedServer = await serverStore.addServer(serverInfo);
        await serverStore.fetchServers();

        if (!addedServer.id) {
            setServerInfo({ caption: "", description: "", domain_name: "" });
        }
        setShowAddServer(false);
    };

    const editServer = async (e) => {
        e.preventDefault();
        if (!serverInfo.caption || !serverInfo.domain_name || !serverInfo.description) {
            toast.error("Missing fields");
            return;
        }
        await serverStore.editServer(serverInfo);
    };

    const deleteServerListRow = async (id) => {
        const confirm = window.confirm("Delete?");
        if (selectedRows.length != 0 && confirm) {
          await Promise.all(selectedRows.map((id) => serverStore.deleteServer(id)));
          setSelectedRows([]); 
        } else if (confirm) {
            await serverStore.deleteServer(id);
        }
      };
      

    const getServerName = (serverId) => {
        const server = serverStore.servers.find((data) => data.id == serverId)
        return server ? server.domain_name : ""
    }

    const deleteDeviceToServerRow = async (id) => {
        const confirm = window.confirm("Delete?");
        if (confirm) {
            await Promise.all(selectedRows.map((id) => serverStore.deleteDevice2Server(id)));
            setSelectedRows([]);
        }
    };

    const toggleCheckbox = (id) => {
        const isSelected = selectedRows.includes(id);
        
        if (isSelected) {
          setSelectedRows((prevRows) => prevRows.filter((rowId) => rowId !== id));
        } else {
          setSelectedRows((prevRows) => [...prevRows, id]);
        }
      };
      


      const handleCheckboxChange = (id) => {
        const isSelected = selectedRows.includes(id);
      
        if (isSelected) {
          setSelectedRows((prevRows) => prevRows.filter((rowId) => rowId !== id));
        } else {
          setSelectedRows((prevRows) => [...prevRows, id]);
        }
      
        handleCheckboxChange && handleCheckboxChange(selectedRows);
      };
      


    return (
        <div>
            {serverStore.loading ? (
                <Spinner />
            ) : (
                <DataTable3
                    title="Server List"
                    data={serverStore.servers
                        .map((item, index) => {
                            return {
                                ...item,
                                SN: index + 1,
                                displayDomainName: item.domain_name.replace('/import', ''),
                            }
                        })

                    }
                    headers={[
                        { key: "SN", label: "SN" },
                        { key: "caption", label: "Caption" },
                        { key: "description", label: "Description" },
                        { key: "displayDomainName", label: "DomainName" },
                    ]}
                    handleAdd={() => setShowAddServer(true)}
                    // handleEdit={(id) => {
                    //     navigate(`/devices/server/${id}`);
                    // }}
                    handleDelete={(e) => deleteServerListRow(e)}
                    handleCheckboxChange={(id) => toggleCheckbox(id, selectedRows, setSelectedRows)}
                />)}

            {showAddServer && (
                <Modal
                    title={"Add server"}
                    closeModal={() => {
                        setShowAddServer(false);
                        setServerInfo({ caption: "", description: "", domain_name: "" });
                    }}
                >
                    <form className="Form">
                        <label>Caption</label>
                        <input
                            type="text"
                            name="caption"
                            value={serverInfo.caption}
                            onChange={setNewForm}
                        />

                        <label>Domain name</label>
                        <input
                            type="text"
                            name="domain_name"
                            value={serverInfo.domain_name}
                            onChange={setNewForm}
                        />

                        <label>Description</label>
                        <input
                            type="text"
                            name="description"
                            value={serverInfo.description}
                            onChange={setNewForm}
                        />
                        <br />
                        <button onClick={(e) => addServer(e)}>Submit</button>
                    </form>
                </Modal>
            )}

            <br />
            {serverStore.loading ? (
                <Spinner />
            ) : (
                <DataTable3
                    title="Device data to server"
                    data={serverStore.device2server
                        .map((item, index) => {
                            return {
                                ...item,
                                SN: index + 1,
                                server_id: getServerName(item.server_id).replace('/import', '')
                            }
                        })

                    }
                    headers={[
                        { key: "SN", label: "SN" },
                        { key: "device_id", label: "Device" },
                        { key: "server_id", label: "Server" },
                    ]}
                    handleAdd={() => setShowAddRedirectingServer(true)}
                    // handleEdit={(id) => {
                    //     navigate(`/servers/device2server/${id}`);
                    // }}
                    handleDelete={deleteDeviceToServerRow}
                    handleCheckboxChange={(id) => toggleCheckbox(id, selectedRows, setSelectedRows)}
                />)}

            {showAddRedirectingServer && (
                <Modal
                    title={"Add Device To Server"}
                    closeModal={() => {
                        setShowAddRedirectingServer(false);
                        setReDirectServerInfo({ device_id: "", server_id: "" });
                    }}
                >
                    <form
                        className="Form"
                        autoComplete="off"
                    >
                        <label>DeviceId</label>
                        <input
                            type="text"
                            name="device_id"
                            value={redirectServerInfo.device_id}
                            autoComplete="off"
                            onChange={setNewServerInfo}
                        />

                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-label">Server</InputLabel>
                            <Select
                                name="server_id"
                                label="server_id"
                                value={redirectServerInfo.server_id}
                                onChange={setNewServerInfo}
                            >
                                {serverStore.servers.map((server, index) => (
                                    <MenuItem key={index} value={server.id}>{server.domain_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <br />
                        <button onClick={(e) => addRedirectingServer(e)}>Submit</button>
                    </form>
                </Modal>
            )}

            <Outlet />
        </div>

    );
});


export default ServerList