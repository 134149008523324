import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import {Spinner} from "./Spinner";
import { wait } from '../../libs/wait';

const FormMultiSelectInput = observer(({ label, id, options, value, onChange, optionClicked, sortBy, isLoading }) => {
  const [openOptions, setOpenOptions] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [readyToClose, setReadyToClose] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [showSelected, setShowSelected] = useState(false);
  const [closing, setClosing] = useState(false);
  const divRef = useRef(null);

  useEffect(() => {
    const windowMouseDownListener = (e) => {
      const element = document.getElementById(id || label);
      if (element && !divRef.current.contains(e.target) && openOptions) {
        setReadyToClose(true);
      }
    };

    const windowMouseUpListener = () => {
      if (readyToClose) {
        toggleOptionOpen();
        setReadyToClose(false);
      }
    };

    const scrollListener = (e) => {
      setScrollTop(e.target.scrollTop);
    };

    window.addEventListener('mousedown', windowMouseDownListener);
    window.addEventListener('mouseup', windowMouseUpListener);

    const modalElement = document.getElementsByClassName('modalChildren');
    if (modalElement && modalElement.length) {
      modalElement[modalElement.length - 1].addEventListener('scroll', scrollListener);
      setScrollTop(modalElement[modalElement.length - 1].scrollTop);
    }

    return () => {
      window.removeEventListener('mousedown', windowMouseDownListener);
      window.removeEventListener('mouseup', windowMouseUpListener);

      if (modalElement && modalElement.length) {
        modalElement[modalElement.length - 1].removeEventListener('scroll', scrollListener);
      }
    };
  }, [openOptions, readyToClose]);

  const toggleOptionOpen = async () => {
    if (openOptions) {
      setClosing(true);
      await wait(80);
      setClosing(false);
    }
    setOpenOptions(!openOptions);
    setShowSelected(false);
    setFilterText('');
  };

  const handleOptionClick = (item) => {
    optionClicked(item); // Invoke the passed function
    const newValue = value.includes(item.id)
      ? value.filter(v => v !== item.id)
      : [...value, item.id];
    onChange(newValue);
  };

  const optionsChangeAll = (selectAll) => {
    const newOptions = toJS(options).map(item => {
      if (filterText === '' || item.label.toLowerCase().startsWith(filterText.toLowerCase())) {
        return { ...item, value: selectAll };
      }
      return item;
    });
    const newValue = selectAll ? newOptions.map(opt => opt.id) : [];
    onChange(newValue);
  };

  const getFilteredOptions = () => {
    if (filterText === '') return options;
    return options.filter(item => item.label.toLowerCase().startsWith(filterText.toLowerCase()));
  };

  const sortData = (data, sortBy) => {
    return data.slice().sort((a, b) => a[sortBy || 'label'].localeCompare(b[sortBy || 'label'], undefined, { numeric: true }));
  };

  const renderOptions = (options) => (
    <div className={`multi-select-options ${closing ? 'multi-select-options-closing' : ''}`} style={{ transform: `translateY(${4 - scrollTop}px)` }}>
      <div className='options-controller'>
        <input type='text' placeholder={`Search ${label}`} onChange={(e) => setFilterText(e.target.value)} style={{ minWidth: '150px' }} autoFocus />
        <i className='button fa fa-check white' onClick={() => optionsChangeAll(true)} data-toggle="tooltip" title="Select All" />
        <i className='button fa fa-close white' onClick={() => optionsChangeAll(false)} data-toggle="tooltip" title="Clear All" />
        <i className={`button fa fa-eye${showSelected ? '' : '-slash'} white`} onClick={() => setShowSelected(!showSelected)} data-toggle="tooltip" title="Show Selected" />
      </div>
      {isLoading && !options.length && <Spinner />}
      <ul>{(showSelected ? options.filter(item => value.includes(item.id)) : options).map(item => (
        <li key={`${id}-option-${item.id}`} onClick={() => handleOptionClick(item)} data-toggle='tooltip' title={item.id}>
          <i className={value.includes(item.id) ? 'fa fa-check-square' : 'fa fa-square'} style={{ color: 'grey' }} />
          {item.label}
        </li>
      ))}</ul>
    </div>
  );

  return (
    <div className={`formInput flex flex-row`}>
      {label && <label className='flex flex-column justify-center'>{label}</label>}
      <div ref={divRef} id={id} className='formInputMulti'>
        <div className={`multi-select-input lightBg`} onClick={toggleOptionOpen}>
        <div>{isLoading ? 'Loading...' : !!options && !!options.length ? `${value.length} selected of ${options.length}` : ''}</div>
          <i className='fa fa-caret-down' />
        </div>
        {openOptions && renderOptions(sortData(getFilteredOptions(), sortBy))}
      </div>
    </div>
  );
});

export default FormMultiSelectInput;
