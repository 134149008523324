import React, { useState, useEffect } from "react";
import "../Common/DataTable/DataTable.css";
import { TablePagination} from "@mui/material";
import ReactSwitch from "react-switch";
import { useContext } from "react";
import { RootContext } from "../../index";

export const RechargeTable = ({
  headers,
  data,
  handleAdd,
  handleEdit,
  handleDelete,
  title,
  onRowClick,
  handleCheckboxChange,
}) => {

  const rootStore = useContext(RootContext);
 const rechargeStore = rootStore.rechargeStore;
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10); 
  const [page, setPage] = useState(0);
  const [checked, setChecked] = useState(true);
  const [remainingCredit, setremainingCredit] = useState(true);
  const [totalCreditConsumed, setTotalCreditConsumed] = useState(true);

  useEffect(() => {
    rechargeStore.fetchCredits();

    const data = rechargeStore.credits

     setremainingCredit(data[0]?.remaining_credit);
      setTotalCreditConsumed(data[0]?.total_credit_consumed);
    
}, [data]);

  const handleChange = async (val) => {
    setChecked(val)

    let obj = {
      toggle:val
    }
    const toggle = await rechargeStore.toggleRecharge(obj);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const toggleCheckbox = (id) => {
    const isSelected = selectedRows.includes(id);
    if (isSelected) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const isRowSelected = (id) => selectedRows.includes(id);

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedRows(selectAll ? [] : data.map((item) => item.id));
  };


  return (
    <div className="data-table-container">
      <div style={{display:"flex",alignItems:"center"}}>
      <ReactSwitch
       checked={checked}
       onChange={handleChange}
    />
    <p style={{marginLeft: "10px"}}>{ checked == true ? "Automatic Recharge Everyday At 12:00 pm" : "Automatic Recharge Stopped"} </p>

    {remainingCredit && totalCreditConsumed && (
     <div style={{display:"flex",flexDirection:"column",marginLeft:"200px"}}>
     <div >Total Credits Consumed:  <span  style={{fontWeight:"bold" }}> Rs {totalCreditConsumed}</span></div>
     <div style={{marginTop:"5px"}}>Remaining Credit: <span  style={{fontWeight:"bold" }}>Rs {remainingCredit} </span> </div>
  </div> 
    )}
      </div>

      <TablePagination
        rowsPerPageOptions={[10, 15, 25]}
        component="div"
        count={data ? data.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <table className="data-table">
        <thead>
          <tr>
            <th style={{ width: 10 }}>
						<input
                type="checkbox"
                checked={selectAll}
                onChange={toggleSelectAll}
              />
						</th>
            {headers.map((title, index) => (
              <th key={index}  style={{ width: title.width }}>{title.label}</th>
            ))}
            <th>
              {handleAdd && (
                <span className="actions">
                  <i className="fa fa-plus" onClick={handleAdd}></i>
                </span>
              )}
            </th>
          </tr>
        </thead>

        <tbody>
          {data &&
            data
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((data, index) => (
              <tr key={index} onClick={() => onRowClick && onRowClick(data)}>
                
                <td>
                  <input
                    type="checkbox"
                    checked={isRowSelected(data.id)}
                    onChange={() => {
                      toggleCheckbox(data.id);
                      handleCheckboxChange && handleCheckboxChange(data.id);
                    }}
                  />
                </td>
                {headers.map((h, i) => (
                  <td style={{ padding: "5px" }} key={i}>
                    {data[h.key]}
                  </td>
                ))}
                <td className="action-icons">
                  {handleEdit && (
                    <i
                      className="fa fa-pencil"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleEdit(data.id, data);
                      }}
                    />
                  )}
                  {handleDelete && (
                    <i
                      className="fa fa-trash"
                      style={{ cursor: "pointer", paddingLeft: "5px" }}
                      onClick={() => {
                        handleDelete(data.id);
                      }}
                    />
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
     
    </div>
  );
};
