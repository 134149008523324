import React, { useState, useEffect } from "react";
import Modal from "../Common/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../../index";
import { observer } from "mobx-react-lite";
import { Outlet, useNavigate } from "react-router-dom";
import { Spinner } from "../Common/Spinner";
import { DataTable3 } from "../Server/DataTable3";
import Form, { FormBody, TextInput, SelectInput } from '../Common/Form';

const Role = observer(() => {
    const rootStore = useContext(RootContext);
    const userStore = rootStore.userStore;

    const [showAddUsers, setShowAddUsers] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);


    const navigate = useNavigate();

    useEffect(() => {
        if (!userStore.users.length) userStore.fetchUsers();
        if (!userStore.roles.length) userStore.fetchRoles();
    }, []);

    const [userInfo, setUserInfo] = useState({
        user: "",
        password: "",
        role: "",
        email: ""
    });

    const setNewForm = (e) => {
        const { name, value } = e.target;
        setUserInfo((prev) => ({ ...prev, [name]: value }));
    };


    const setNewForm1 = (name, e) => {
        const { value } = e.target
        setUserInfo((prev) => ({ ...prev, [name]: value }));
    };

    const addUser = async (e) => {
        e.preventDefault();
        if (!userInfo.user || !userInfo.password || !userInfo.role || !userInfo.email) {
            toast.error("Missing fields");
            return;
        }
        const addedUserInfo = await userStore.registerUser(userInfo);

        if (!addedUserInfo.id) {
            await userStore.fetchUsers();

            setUserInfo({
                user: "",
                password: "",
                role: "",
                email: ""
            });
        }

        setShowAddUsers(false)
        toast.success("User Added");
    };


    const deleteSelectedUsers = async (id) => {
        const confirm = window.confirm("Delete?");
        if (selectedRows.length != 0 && confirm) {
            await Promise.all(selectedRows.map((id) => userStore.deleteUsers(id)));
            setSelectedRows([]);
        } else if (confirm) {
            await userStore.deleteUsers(id);
        }
    }

    const toggleCheckbox = (id) => {
        const isSelected = selectedRows.includes(id);

        if (isSelected) {
            setSelectedRows((prevRows) => prevRows.filter((rowId) => rowId !== id));
        } else {
            setSelectedRows((prevRows) => [...prevRows, id]);
        }
    };

    const handleCheckboxChange = (id) => {
        const isSelected = selectedRows.includes(id);

        if (isSelected) {
            setSelectedRows((prevRows) => prevRows.filter((rowId) => rowId !== id));
        } else {
            setSelectedRows((prevRows) => [...prevRows, id]);
        }

        handleCheckboxChange && handleCheckboxChange(selectedRows);
    };

    return (
        <div>
            {userStore.loading ? (
                <Spinner />
            ) : (
                <DataTable3
                    title="User List"
                    data={userStore.users
                        .map((item, index) => {
                            return {
                                ...item,
                                SN: index + 1,
                            }
                        })

                    }
                    headers={[
                        { key: "SN", label: "SN", width: 50 },
                        { key: "username", label: "User", width: 200 },
                        { key: "user_role", label: "Role" },
                        { key: "user_email", label: "Email" }
                    ]}
                    handleAdd={() => setShowAddUsers(true)}
                    handleEdit={(id) => {
                        navigate(`/configuration/roles/${id}`);
                    }}
                    handleDelete={(e) => deleteSelectedUsers(e)}
                    handleCheckboxChange={(id) => toggleCheckbox(id, selectedRows, setSelectedRows)}
                />)}

            {showAddUsers && (
                <Modal
                    title={"Add Users"}
                    closeModal={() => {
                        setShowAddUsers(false);
                        setUserInfo({
                            user: "",
                            password: "",
                            role: ""
                        });
                    }}
                >

                    <Form>
                        <FormBody>
                            <TextInput label={'User'} name="user" value={userInfo.user} onChange={setNewForm} />
                            <TextInput label={'Password'} name="password" value={userInfo.password} onChange={setNewForm} />
                            <TextInput label={'Email'} name="email" value={userInfo.email} onChange={setNewForm} />
                            {/* <TextInput label={'Role'} name="role" value={userInfo.role} onChange={setNewForm} /> */}
                            <SelectInput
                                    label={'Role'}
                                    name={'role'}
                                    fields={{ label: 'name', value: 'name' }}
                                    options={userStore.roles}
                                    value={userInfo.role}
                                    onChange={(value) => setNewForm1("role", value)}
                                />
                            <br />
                            <button onClick={(e) => addUser(e)}>Submit</button>
                        </FormBody>
                    </Form>
                </Modal>
            )}
            <Outlet />
        </div>

    );
});


export default Role
