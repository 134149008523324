import React, { useState, useEffect, useContext } from "react";
import { WithContext as ReactTags } from 'react-tag-input';
import "./Tags.css"
import { observer } from "mobx-react-lite";
import { RootContext } from "../../index";

const Tags = observer(({deviceTag, onUpdateTags}) => {
  const rootStore = useContext(RootContext);
  const tagStore = rootStore.tagStore;

  const tagList = deviceTag.map((data) => {return {id:data.id,name:data.name}})
  const suggestions = tagStore.tags.map((item)=>{return {id:item.id,name:item.caption}})

  const [tags, setTags] = useState([]);

  useEffect(() => {
    tagStore.fetchTags()
    setTags(tagList)
  },[deviceTag])
  
  const handleDelete = i => {
    let updatedTags = tags.filter((tag,index) => index !== i)
    setTags(updatedTags);
    onUpdateTags(updatedTags)
  };

  // const handleDelete = i => {
  //   console.log(i,"i");
  //   let tagsUpdate= tags.map((tag,index) => {
  //     if (index !== i){
  //       console.log(true,"truee");
  //       return { ...tag, flag: false };
  //     }
  //     return { ...tag, flag: true };
  //   })
  //   console.log(tagsUpdate,"tagsupdate");
  //   let updatedTags = tagsUpdate.filter((tag,index) => index !== i)
  //   setTags(updatedTags);
  //   onUpdateTags(tagsUpdate)
  // };


  const handleAddition = tag => {
    const tagList = tagStore.tags
    setTags([...tags, tag]);
    onUpdateTags([...tags, tag])
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    setTags(newTags);
    onUpdateTags(newTags)
  };

  return (
    <div className="tags">
      <div>
        <ReactTags
          tags={tags}
          suggestions={suggestions}
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          handleDrag={handleDrag}
          labelField={"name"}
          inputFieldPosition="bottom"
          autocomplete
        />
      </div>
    </div>
  );
});

export default Tags