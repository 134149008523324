import React, { useEffect, useState, useContext } from 'react'
import Graph from './Graph'
import { observer } from "mobx-react-lite";
import { RootContext } from "../../index.js";
import { DateInput } from "../Common/Form.js"
import moment from "moment"
import { useParams } from "react-router-dom";
import "./dataUsage.css"
require("moment-timezone")

const DataUsage = observer(() => {
  const rootStore = useContext(RootContext);
  const dataUsageStore = rootStore.dataUsageStore

  const { id } = useParams();

  const [filters, setFilters] = useState({
    date_from: moment().subtract(7, "days").format("YYYY-MM-DD"),
    date_to: moment().format("YYYY-MM-DD")
  });

  const setNewFilters = (filter, value) => {
    const dateTimeValue = moment.utc(value).startOf('day').toISOString();
    setFilters((prev) => ({ ...prev, [filter]: dateTimeValue }));
  };

  useEffect(() => {
    dataUsageStore.fetchUsageById(id);
  }, [dataUsageStore, id]);

  const fromDate = moment(filters.date_from);
  const toDate = moment(filters.date_to)


  let data = dataUsageStore.usageDetailsById.filter((item) => {
    let dataTime = moment(item.datatime).utc();
    return dataTime.isBetween(fromDate, toDate, undefined, '[]');
  });

  const dataConsumption = (data) => {
    if (!data || data.length == 0) {

      return {
        totalDataConsumption: 0,
        maxDataConsumption: 0,
        averageDataConsumption: 0,
        minimumDataConsumption: 0,
      };
    }

    const consumedDataSizeArray = data && data.map((item) => item.y).filter((number)=> number !=0)
    const dataLength = consumedDataSizeArray.length
    const totalDataConsumption = consumedDataSizeArray.reduce((a, b) => a + b, 0)

    const averageDataConsumption = (totalDataConsumption / dataLength).toFixed(2)
    const maxDataConsumption = Math.max(...consumedDataSizeArray)
    const minimumDataConsumption = Math.min(...consumedDataSizeArray)


    return { totalDataConsumption, maxDataConsumption, averageDataConsumption, minimumDataConsumption }
  }

  // const dataConsumed = dataConsumption(data)

  const previousMonthDataConsumption = () => {
    const date_from = moment().utc().subtract(1, "month").startOf('month').add("5.45", "hour").format('YYYY-MM-DD HH:mm:ss');
    const date_to = moment().utc().subtract(1, "month").endOf('month').add("5.45", "hour").format('YYYY-MM-DD HH:mm:ss');

    let data = dataUsageStore.usageDetailsById.filter((item) => {
      let dataTime = moment(item.datatime).utc();
      return dataTime.isBetween(date_from, date_to, undefined, '[]');
    });

    const consumedDataSizeArray = data && data.map((item) => item.size)
    const totalDataConsumption = consumedDataSizeArray.reduce((a, b) => a + b, 0)

    return totalDataConsumption

  }

  const previousMonthDataConsumed = previousMonthDataConsumption()

  let dataConsumed
  if (moment(toDate).diff(fromDate, 'days') > 1) {
    const aggregatedData = {};
    data.forEach((item) => {
      const dateTime = moment(item.datatime).utc();
      const day = dateTime.format('YYYY-MM-DD');
      if (!aggregatedData[day]) {
        aggregatedData[day] = 0;
      }
      aggregatedData[day] += item.size;
    });

    const startDate = moment(filters.date_from);
    const stopDate = moment(filters.date_to)

    function getDates(startDate, stopDate) {
      var dateArray = [];
      var currentDate = moment(startDate);
      var stopDate = moment(stopDate);
      while (currentDate <= stopDate) {
        dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
        currentDate = moment(currentDate).add(1, 'days');
      }
      return dateArray;
    }

     data = Object.keys(aggregatedData).map((day) => ({
      name: moment(day).format('YYYY-MM-DD'),
      y: aggregatedData[day],
    }))

    const datesArray = getDates(startDate, stopDate)
    const remainingdates = datesArray && datesArray.filter(item => !data.some(days => days.name == item)).map((item) => {
      data.push ({name:item, y:0})
    })

    dataConsumed = dataConsumption(data)
  } else {
    const requiredHours = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"];

    const checkHours = (hour, data) => {
      const checkedData = data && data.some((item) => {
        const datetime = moment(item.datatime).utc().format("HH")
        return datetime.includes(hour)
      })
      return checkedData
    }

    requiredHours.forEach((hours) => {
      if (!checkHours(hours, data)) {
        const firstDataTime = data?.[0]?.datatime.split("T")[0]
        const newDataPoint = { datatime: `${firstDataTime}T${hours}:00:00.000Z`, size: 0 };
        data.push(newDataPoint);
      }
    })

    data = data.map(({ datatime, size }) => ({
      name: moment(datatime).toISOString(),
      y: size,
    }));
    dataConsumed = dataConsumption(data)
  }


  return (
    <div className='datausage'>
      <div>
        <DateInput
          label={"Date From"}
          type="date"
          value={moment(filters.date_from).format("YYYY-MM-DD")}
          onChange={(e) => setNewFilters("date_from", e.target.value)}
        />
        <DateInput
          label={"Date To"}
          type="date"
          value={moment(filters.date_to).format("YYYY-MM-DD")}
          onChange={(e) => setNewFilters("date_to", e.target.value)}
        />
      </div>

    <br/><br/><br/>
      <Graph graphData={data} />

      <div className='statics'>
        {dataConsumed && (
          <div className='current-month'>
            <div><span className='label'>Average Data Consumption: </span> <span className='value'>{dataConsumed.averageDataConsumption} bytes</span></div>
            <div><span className='label'>Maximum Data Consumption: </span> <span className='value'>{dataConsumed.maxDataConsumption} bytes</span></div>
            <div><span className='label'>Minimum Data Consumption: </span> <span className='value'>{dataConsumed.minimumDataConsumption} bytes</span></div>
            <div><span className='label'>Total Data Consumption: </span> <span className='value'>{dataConsumed.totalDataConsumption} bytes</span></div>
          </div>
        )
        }
        {previousMonthDataConsumed && (
          <div className='previous-month'>
            <div><span className='label'>Total Data Consumed Previous Month: </span><span className='value'>  {previousMonthDataConsumed} bytes</span></div>
          </div>
        )}

      </div>

    </div>


  )
})

export default DataUsage