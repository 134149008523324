import React, { useState, useEffect } from "react";
import Modal from "../../Common/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../../../index";
import { observer } from "mobx-react-lite";
import { useParams, useNavigate } from "react-router-dom";
import Form, { FormBody, TextInput} from '../../Common/Form';

const EditServiceProvider = observer(() => {
	const rootStore = useContext(RootContext);
	const serviceProviderStore = rootStore.serviceProviderStore;

	const [caption, setCaption] = useState("");
	const [description, setDescription] = useState("");

	const { id } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (!serviceProviderStore.serviceProvider.length) serviceProviderStore.fetchServiceProvider();
		const currentserver = serviceProviderStore.serviceProvider.find((serviceProvider) => serviceProvider.id === id);
		setCaption(currentserver?.caption);
		setDescription(currentserver?.description);
	}, []);

	const editServiceProvider = async (e) => {
		e.preventDefault();
		if (!caption || !description) {
			toast.error("Missing fields");
			return;
		}
		const result = await serviceProviderStore.editServiceProvider(id, { caption: caption, description: description });
		if (result) {
			navigate("/configuration/serviceProvider")
			toast.success("Service Provider edited successfully");
		};
	};

	return (
		<>
			<Modal
				title={"Edit Service Provider Details"}
				closeModal={() => {
					navigate("/configuration/serviceProvider");
				}}
			>
					<Form>
						<FormBody>
							<TextInput label={'Caption'} name="caption" value={caption} onChange={(e) => setCaption(e.target.value)} />
							<TextInput label={'Description'} name="description" value={description} onChange={(e) => setDescription(e.target.value)} />
							<br />
							<button onClick={editServiceProvider}>Submit</button>
						</FormBody>
					</Form>

				
			</Modal>
		</>
	);
});

export default EditServiceProvider