import { makeAutoObservable, runInAction } from "mobx";
import { callApi } from "../actions/apiMethods";
import { toast } from "react-toastify";

export class simDetailStore{
  details = []
	email = []
	details2 = []
	details3 = []
	details4 = []
	emails = []
	details5 = []
	checkerResponse = []
	toRechargeData = []

  constructor() {
		makeAutoObservable(this);
	}

	addSimDetails = async (formEntries) => {
		const response = await callApi("POST", "api/registerSim", formEntries);
		if (!response) return;
		runInAction(() => {
			this.details.push(formEntries);
			this.loading = false;
		});
		return response.details;
	};

	deleteSimDetails = async (deviceId) => {
		const response = await callApi("DELETE", `api/deletesimdetails/${deviceId}`);
		if (!response) return;
		runInAction(() => {
			this.details = this.details.filter((device) => device.id !== deviceId);
			this.loading = false;
		});
		// toast.success("Device deleted");
	};

	editSimDetails = async (id, divInfo) => {
		const response = await callApi("PUT", `api/updatesimdetails/${id}`, divInfo);
		if (!response) return;
		runInAction(() => {
			this.details = this.details.map((div) => (div.device_id === id ? { ...div, ...divInfo } : div));
			this.loading = false;
		});
		toast.success(response.message);
		return response.message;
	};

  fetchSimDetails = async () => {
		const response = await callApi("GET", "api/simdetails");
		if (!response) return;
		runInAction(() => {
			this.details = response.simDetails;
		});
	};

	fetchOnlySimDetail = async () => {
		const response = await callApi("GET", "api/sim-detail");
		if (!response) return;
		runInAction(() => {
			this.details4 = response.simDetails;
		});
	};

	fetchSimDetailsByNum = async (num) => {
		const response = await callApi("GET", `api/simdetails/${num}`);
		if (!response) return;
		runInAction(() => {
			this.details2 = response.details;
		});
	};

	fetchSimDetailsByDeviceId = async (id) => {
		const response = await callApi("GET", `api/simdetailsbydeviceid/${id}`);
		if (!response) return;
		runInAction(() => {
			this.details3 = response.details;
		});
	};

	fetchSimDetailsByPhoneNumber = async (id) => {
		const response = await callApi("GET", `api/simdetailsphonenumber/${id}`);
		if (!response) return;
		runInAction(() => {
			this.details5 = response.details;
		});
	};

	createEmail = async (formEntries) => {
		const response = await callApi("POST", "api/registeremail", formEntries);
		if (!response) return;
		runInAction(() => {
			this.email.push(formEntries);
			this.loading = false;
		});
		return response.message;
	};

	postEmail = async (formEntries) => {
		const response = await callApi("POST", "api/sendemail", formEntries);
		if (!response) return;
		runInAction(() => {
			this.email.push(formEntries);
			this.loading = false;
		});
		return response.message;
	};

	fetchEmailDetailByDeviceId = async (id) => {
		const response = await callApi("GET", `api/emaildetail/${id}`);
		if (!response) return;
		runInAction(() => {
			this.emails = response.details;
		});
	};

	automaticPasswordChecker = async (formEntries) => {
		const response = await callApi("POST", "api/passwordchecker", formEntries);
		if (!response) return;
		return response
	};

	fetchToRechargeSim = async () => {
		const response = await callApi("GET", `api/torechargesim`);
		if (!response) return;
		runInAction(() => {
			this.toRechargeData = response.details;
		});
	};

	
}