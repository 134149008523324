import { makeAutoObservable, runInAction } from "mobx";
import { callApi } from "../actions/apiMethods";
import { toast } from "react-toastify";

export class tagStore {
	tags = []
	meta_data = []
	meta_data_template = []
	meta_data_template_meta_data = []
	meta_data_template_meta_data2 = []
	deviceMetaData = []
	device_meta_data = []
	device_tags = []

	constructor() {
		makeAutoObservable(this);
	}

	postTags = async (formEntries) => {
		const response = await callApi("POST", "api/tags", formEntries);
		if (!response) return;
		runInAction(() => {
			this.tags.push(formEntries);
		});
		return response.details;
	};


	fetchTags = async () => {
		const response = await callApi("GET", "api/tags");
		if (!response) return;
		runInAction(() => {
			this.tags = response.details;
		});
	};

	deleteTag = async (tagId) => {
		const response = await callApi("DELETE", `api/tags/${tagId}`);
		if (!response) return;
		runInAction(() => {
			this.tags = this.tags.filter((tag) => tag.id !== tagId);
		});
		// toast.success("Device deleted");
	};

	fetchTagsById = async (device_id) => {
		const response = await callApi("GET", `api/tag_device/${device_id}`);
		const response2 = await callApi("GET", "api/tags");

		const tagItems = response.details.map((data) => {
			const tagName = response2.details.find((tag) => tag.id == data.tag_id).caption;
			const tagId = data.tag_id

			return { name: tagName, id: tagId }

		})
		if (!response) return;
		runInAction(() => {
			this.device_tags = tagItems;
		});
	};

	editDeviceTags = async (id, divInfo) => {
		const response = await callApi("PUT", `api/tag_device_edit/${id}`, divInfo);
		if (!response) return;
		runInAction(() => {
			this.device_tags = this.device_tags.map((div) => (div.device_id === id ? { ...div, ...divInfo } : div));
			this.loading = false;
		});
		return response.message;
	};


	addEditMetaDataTemplate = async (formEntries) => {
		const response = await callApi("POST", "api/metadata_template_metadata", formEntries);
		if (!response) return;
		runInAction(() => {
			this.meta_data_template_meta_data.push(formEntries);
			this.loading = false;
		});
		return response.details;
	};

	postMetaData = async (formEntries) => {
		const response = await callApi("POST", "api/meta_data", formEntries);
		if (!response) return;
		runInAction(() => {
			this.meta_data.push(formEntries);
		});
		return response.details;
	};

	fetchMetaData = async () => {
		const response = await callApi("GET", "api/meta_data");
		if (!response) return;
		runInAction(() => {
			this.meta_data = response.details;
		});
	};

	deleteMetaData = async (metaDataId) => {
		const response = await callApi("DELETE", `api/meta_data/${metaDataId}`);
		if (!response) return;
		runInAction(() => {
			this.meta_data = this.meta_data.filter((metadata) => metadata.id !== metaDataId);
		});
		// toast.success("Device deleted");
	};

	postMetaDataTemplate = async (formEntries) => {
		const response = await callApi("POST", "api/metadata_template", formEntries);
		if (!response) return;
		runInAction(() => {
			this.meta_data_template.push(formEntries);
		});
		return response.details;
	};

	editMetaDataTemplate = async (id, divInfo) => {
		const response = await callApi("PUT", `api/meta_data_template_edit/${id}`, divInfo);
		if (!response) return;
		toast.success(response.message);
		return response.message;
	};

	fetchMetaDataTemplate = async () => {
		const response = await callApi("GET", "api/metadata_template");
		if (!response) return;
		runInAction(() => {
			this.meta_data_template = response.details;
		});
	};


	deleteMetaDataTemplate = async (metaDataId) => {
		const response = await callApi("DELETE", `api/meta_data_template/${metaDataId}`);
		if (!response) return;
		runInAction(() => {
			this.meta_data_template = this.meta_data_template.filter((metadata) => metadata.id !== metaDataId);
		});
		// toast.success("Device deleted");
	};


	fetchMetaDataTemplateById = async (templateId) => {
		const response = await callApi("GET", `api/metadata_template/${templateId}`);
		if (!response) return;
		runInAction(() => {
			this.meta_data_template = response.details;
		});
	};

	editDeviceMetaData = async (id, divInfo) => {
		const response = await callApi("PUT", `api/device_metadata/${id}`, divInfo);
		if (!response) return;
		runInAction(() => {
			this.device_meta_data = this.device_meta_data.map((div) => (div.device_id === id ? { ...div, ...divInfo } : div));
			this.loading = false;
		});
		toast.success(response.message);
		return response.message;
	};

	deleteDeviceMetaData = async (metaDataId) => {
		const response = await callApi("DELETE", `api/device_meta_data/${metaDataId}`);
		if (!response) return;
		runInAction(() => {
			this.device_meta_data = this.device_meta_data.filter((metadata) => metadata.id !== metaDataId);
		});
		// toast.success("Device deleted");
	};


	fetchMetaDataTemplateMetaDataById = async (templateId) => {
		const response = await callApi("GET", `api/metadata_template_metadata/${templateId}`);
		const response2 = await callApi("GET", "api/meta_data");
		const rightItems = response.details.map((data) => {
			const metaDataName = response2.details.find((metadata) => metadata.id == data.meta_data_id).name;
			const metaDataId = data.meta_data_id
			const sequence = data.seq

			return { name: metaDataName, seq: sequence, metadataid: metaDataId }
		})

		const leftItems = response2.details.filter((metadata) => {
			return !rightItems.some((item) => item.metadataid == metadata.id);
		}).map((metadata) => {
			return { name: metadata.name, seq: metadata.seq, metadataid: metadata.id };
		});
		if (!response) return;
		runInAction(() => {
			this.meta_data_template_meta_data = [rightItems, leftItems];
		});
	};

	fetchMetaDataTemplateMetaDataById2 = async (templateId) => {
		if (templateId == "") {
			templateId = 1
		} 
		const response = await callApi("GET", `api/metadata_template_metadata/${templateId}`);
		const response2 = await callApi("GET", "api/meta_data");
		const rightItems = response.details.map((data) => {
			const metaDataName = response2.details.find((metadata) => metadata.id == data.meta_data_id).name;
			const metaDataId = data.meta_data_id
			const sequence = data.seq

			return { name: metaDataName, seq: sequence, metadataid: metaDataId }
		})

		if (!response) return;
		runInAction(() => {
			this.meta_data_template_meta_data2 = rightItems;
		});
	};

	fetchDeviceMetaData = async (templateId, deviceId, metaDataTemplateId) => {
		const response = await callApi("GET", `api/metadata_template_metadata/${templateId}`);
		const response2 = await callApi("GET", "api/meta_data");
		const response3 = await callApi("GET", `api/device_meta_data/${deviceId}`);

		const rightItems = response3.details.map((data) => {
			const metaDataName = response2.details.find((metadata) => metadata.id == data.meta_data_id).name;
			const value = data.value

			return { name: metaDataName, value: value }
		})

		if (!response) return;
		runInAction(() => {
			this.deviceMetaData = rightItems;
		});
	};


}