import React, { useState, useEffect } from "react";
import Modal from "../../Common/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { RootContext } from "../../../index";
import { observer } from "mobx-react-lite";
import { Outlet, useNavigate } from "react-router-dom";
import Form, { FormBody, TextInput} from '../../Common/Form';
import { DataTable5 } from "./DataTable5";

const MetaDataTemplate = observer(() => {
    const rootStore = useContext(RootContext);
    const tagStore = rootStore.tagStore;

    const [showAddTags, setShowAddTags] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);

    const [metaDataTemplateInfo, setMetaDataTemplateInfo] = useState({
        caption: "",
        description: "",
    });


    const navigate = useNavigate();

    useEffect(() => {
      tagStore.fetchMetaDataTemplate();
    }, []);

    const setNewForm = (e) => {
        const { name, value } = e.target;
        setMetaDataTemplateInfo((prev) => ({ ...prev, [name]: value }));
    };

    const addMetaDataTemplate = async (e) => {
        e.preventDefault();
        if (!metaDataTemplateInfo.caption || !metaDataTemplateInfo.description) {
            toast.error("Missing fields");
            return;
        }
        const addedmetaDataTemplateInfo = await tagStore.postMetaDataTemplate(metaDataTemplateInfo);
            await tagStore.fetchMetaDataTemplate();

            setMetaDataTemplateInfo({ caption: "", description: "" });


        setShowAddTags(false)
    };


    const deleteMetaDataTemplate = async (id) => {
        const confirm = window.confirm("Delete selected template?");
        if (selectedRows.length != 0  && confirm) {
          await Promise.all(selectedRows.map((id) => tagStore.deleteMetaDataTemplate(id)));
          setSelectedRows([]); 
        } else if (confirm) {
            await tagStore.deleteMetaDataTemplate(id);
        }
      };

      const toggleCheckbox = (id) => {
        const isSelected = selectedRows.includes(id);
        
        if (isSelected) {
          setSelectedRows((prevRows) => prevRows.filter((rowId) => rowId !== id));
        } else {
          setSelectedRows((prevRows) => [...prevRows, id]);
        }
      };
      


      const handleCheckboxChange = (id) => {
        const isSelected = selectedRows.includes(id);
      
        if (isSelected) {
          setSelectedRows((prevRows) => prevRows.filter((rowId) => rowId !== id));
        } else {
          setSelectedRows((prevRows) => [...prevRows, id]);
        }
      
        handleCheckboxChange && handleCheckboxChange(selectedRows);
      };

      
    return (
        <div>
                <DataTable5
                    data={tagStore.meta_data_template
                        .map((item, index) => {
                            return {
                                ...item,
                                SN: index + 1,
                            }
                        })

                    }
                    headers={[
                        { key: "SN", label: "SN" },
                        { key: "caption", label: "Caption" },
                        { key: "description", label: "Description" },
                    ]}
                    handleAdd={() => setShowAddTags(true)}
                    handleEdit={(id) => {
                        navigate(`/configuration/metadata/metadatatemplate/${id}`);
                    }}
                    handleDelete={(e) => deleteMetaDataTemplate(e)}
                    handleCheckboxChange={(id) => toggleCheckbox(id, selectedRows, setSelectedRows)}
                />

            {showAddTags && (
                <Modal
                    title={"Add Template"}
                    closeModal={() => {
                        setShowAddTags(false);
                        setMetaDataTemplateInfo({ caption: "", description: "", volume:"" });
                    }}
                >
                    <Form>
                        <FormBody>
                            <TextInput label={'Caption'} name="caption"  value={metaDataTemplateInfo.caption} onChange={setNewForm} />
                            <TextInput label={'Description'} name="description"  value={metaDataTemplateInfo.description} onChange={setNewForm} />
                            <br/>
                            <button onClick={(e) => addMetaDataTemplate(e)}>Submit</button>
                        </FormBody>
                    </Form>
                </Modal>
            )}
            <Outlet />
        </div>

    );
});


export default MetaDataTemplate
