import React, { useContext, useEffect, useState } from 'react'
import Form, { FormBody, FormActions, TextInput, SelectInput } from '../../Common/Form';
import { RootContext } from "../../../index";
import { observer } from 'mobx-react-lite';

const TimeConverter = observer (() => {
  const rootStore = useContext(RootContext);
  const uiStore = rootStore.uiStore

  const [time, setTime] = useState({
    epochTime: '',
    time2: ''
  });

  const [convertedTime, setConvertedTime] = useState('')

  const convert1 = async (e) => {
    e.preventDefault();

    function fat_data_converter(packedTime) {
      var tptr = {};
      var dptr = {};

      tptr.sec = (packedTime & 0x1F) << 1;
      packedTime >>= 5;

      tptr.min = packedTime & 0x3F;
      packedTime >>= 6;

      tptr.hr = packedTime & 0x1F;
      packedTime >>= 5;

      dptr.dom = packedTime & 0x1F;
      packedTime >>= 5;

      dptr.month = packedTime & 0x0F;
      packedTime >>= 4;

      dptr.year = (packedTime & 0x7F) + 1980;

      var formattedDate = `${dptr.year}-${padZero(dptr.month)}-${padZero(dptr.dom)} `;
      formattedDate += `${padZero(tptr.hr)}:${padZero(tptr.min)}:${padZero(tptr.sec)}`;

      return formattedDate;
    }

    function padZero(num) {
      return num.toString().padStart(2, '0');
    }

    let finalDate = fat_data_converter(time.epochTime)
    setConvertedTime(finalDate)

  };

  const setNewForm = (e) => {
    const { name, value } = e.target;
    setTime((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div>
      <div className='inline-grid space-between' style={uiStore.isNavOpen ? {} : { marginLeft: '80px', marginTop:'50px' }}>
        <div>
          <Form>
            <FormBody>
              <TextInput label={'Epoch Time'} name={'epochTime'} value={time.epochTime} onChange={setNewForm} />
              <button style={{ maxWidth: "60%" }} onClick={convert1} >Convert</button>
              {convertedTime && (
                <div style={{marginTop:'20px'}}> The converted time is :     {convertedTime}</div>
              )}
            </FormBody>
          </Form>
        </div>


      </div>
    </div>
  )
})

export default TimeConverter