import React, { useState, useEffect, useContext } from "react";
import Form, { FormBody, FormActions, TextInput, SelectInput } from '../Common/Form'
import { useParams, useNavigate } from "react-router-dom";
import { RootContext } from "../../../src/index";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";
import "../DragAndDrop/dnd.css"
import { token } from '../../libs/localStorage';

const CommMetaData = observer(() => {
  const rootStore = useContext(RootContext);
  const deviceStore = rootStore.deviceStore;
  const serverStore = rootStore.serverStore;

  const [addedServerOptions, setAddedServerOptions] = useState([])
  const [deviceId, setDeviceId] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [plan, setPlan] = useState("");
  const [provider, setProvider] = useState("");
  const [protocol, setProtocol] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();
  const tokenDecoded = token.getDecoded();

  useEffect(() => {

    (async () => {
      await deviceStore.fetchDeviceByDeviceId(id)
      deviceStore.fetchdevice2server()
      serverStore.fetchServers();

      let [currentDevice] = deviceStore.deviceByDeviceIdDetails
      const serverSelection = deviceStore.items.filter((device) => device.device_id == currentDevice.device_id)
      const serverId = serverSelection.map((data) => data.server_id)

      const serverDomainUrl = serverId.map((data) => {
        const value = serverStore.servers.find((server) => server.id == data)
        return value ? value.domain_name : ""
      })

      const requiredData = serverDomainUrl.map((data) => {
        return { serverSelection: data }
      })

      setDeviceId(currentDevice?.device_id)
      setSerialNumber(currentDevice?.serial_no)
      setAddedServerOptions(requiredData)
      setPlan(currentDevice?.plan)
      setProtocol(currentDevice?.protocol)
      setProvider(currentDevice?.service_provider)
    })()

  }, [id, deviceId]);

  const removeServerOption = (index) => {
    if (tokenDecoded.role == "admin") {
      const updatedServerOptions = addedServerOptions.slice();
      updatedServerOptions.splice(index, 1);
      setAddedServerOptions(updatedServerOptions);
    } else {
      toast.error("Not Authorized")
    }
  };

  const setNewAddedServerInfo = (e, name) => {
    const { value } = e.target;
    if (token.getDecoded().role == "admin") {
      setAddedServerOptions((prev) => [...prev, { [name]: value }]);
    } else {
      toast.error("Not Authorized")
    }

  };

  const getServerId = (serverName) => {
    const value = serverStore.servers.find((server) => server.domain_name == serverName)
    return value ? value.id : ""
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    try {
      if (tokenDecoded.role == "admin") {
      const devicesWithCounter = deviceStore.items.filter(device => device.counter !== null && device.counter !== undefined) .reduce((max, device) => Math.max(max, device.counter), -Infinity);;
      const deleteDevices = await deviceStore.deleteDevice2(deviceId)

      const addDevicePromises = addedServerOptions.map(async (serverOption) => {
        const serverId = getServerId(serverOption.serverSelection);
        const adddevice = await deviceStore.addDevice2Server({ serverId, deviceId, counter: devicesWithCounter });
        return adddevice
      });

      await Promise.all(addDevicePromises);

      toast.success("Success") 
    }
    else {
      toast.error("Not Authorized")
    }
    } catch (error) {
      console.log(error, "error")
    }

  }
  //

  const handleDelete = async (e) => {
    // e.preventDefault();
    const confirm = window.confirm("Are you sure you want to delete this?");
    if (!!confirm) {
      const returnedId = await deviceStore.deleteDevice(id)
      if (!!returnedId) {
        toast.success("Success")
        navigate("/monitoring/dashboard");
      } else {
        toast.error("Failed")
      }
    }

  }

//
  return (
    <div>
      <div className='inline-grid space-between'>
        <div>
          <Form>
            <FormBody>
              <TextInput label={'Serial Number'} name={'serialnumber'} value={serialNumber} readOnly />
              <TextInput label={'DeviceId'} name={'deviceId'} value={deviceId} readOnly />
              <TextInput label={'Orbcomm Plan'} name={'orbcommPlan'} value={plan} readOnly />
              <TextInput label={'Protocol'} name={'protocol'} value={protocol} readOnly />
              <TextInput label={'Provider'} name={'provider'} value={provider} readOnly />
              <SelectInput
                label={'Server Options'}
                fields={{ label: 'domain_name', value: 'domain_name' }}
                options={serverStore.servers}
                onChange={(e) => setNewAddedServerInfo(e, "serverSelection")}
                value={addedServerOptions.serverSelection}
              />

            </FormBody>
            <br /><br />
            <div >
              {addedServerOptions.map((serverOption, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="dndItem">{serverOption.serverSelection}
                    <button
                      onClick={() => removeServerOption(index)}
                      style={{ position: 'relative', top: '0', right: '0', fontSize: '6px', padding: '4px', cursor: 'pointer' }}>X</button>
                  </div>

                </div>
              ))}
            </div>

            <br /><br />
            <button style={{ maxWidth: "20%" }} onClick={onSubmit}>Save</button>
          </Form>
        </div>
      </div>
      {tokenDecoded && tokenDecoded.role == 'admin'? (<button className='delete-number' style={{ minWidth: "10%",background:'red' }} onClick={handleDelete}>Delete</button>):(null)}
       
    </div>
  )

})

export default CommMetaData